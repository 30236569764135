.mobileInfluencerCampaignsSummary {
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.influencerCampaignWallTabs {
    height: calc(100% - 170px) !important;
}

.mediaItems {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
}