.mainPlansSelection,
.packagesSelection {
    height: calc(100vh - 72px);
    background: #F6F3F0;
    overflow-y: auto;
}

.brandRegistration .form-control {
    border-color: #cccccc;
}

img.blueArrowText {
    position: absolute;
    top: 1rem;
    width: 15rem;
    left: 45%;
}

img.pinkCurveLine {
    position: absolute;
    top: 4.3rem;
    left: 15rem;
    z-index: 1;
    height: 2rem;
    transform: rotate(3deg);
}

img.polygonalPics {
    position: absolute;
    right: -7%;
    height: 15rem;
    top: calc(60% - 8rem);
    z-index: 1;
}

.position-overlay {
    position: relative;
    z-index: 9;
}

.signupRight .form-control {
    appearance: auto;
    height: 38px;
}

.mainPlansSelection .card-header.bg-gray,
.packagesSelection .card-header.bg-gray {
    background-color: var(--lightColor) !important;
}

.mainPlansSelection .card {
    min-height: 320px;
}

.packagesSelection .card {
    min-height: auto;
}

.min-h-auto {
    min-height: auto !important;
}

.mainPlansSelection .card:hover,
.packagesSelection .card:hover {
    border: 1px solid #007AA6 !important;
}

.packagesSelection .card:hover .card-footer.bg-gray {
    background-color: #007AA6 !important;
    color: white !important;
}

.mainPlansSelection span.text-muted.smaller {
    font-size: .65rem;
}

.packagesSelection .card-text ul li::marker {
    color: #007AA6;
}

.packagesSelection .recommended {
    position: absolute;
    z-index: 9;
    top: -25px;
    width: calc(100% + 2px);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: #2EC7DE;
    color: #fff;
    border-radius: 12px 12px 0 0;
    border: 1px solid #2EC7DE;
    left: -1px;
    font-size: 1rem;
    line-height: 1rem;
    padding: 5px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem;
    }
    img.pinkCurveLine {
        top: 1.3rem !important;
        left: 12rem !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .signupBody .signupRight {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .signupBody .signupSummaryText {
        min-height: 50vh !important;
        height: auto !important;
    }
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem !important;
    }
    img.pinkCurveLine {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .signupBody .signupRight, .signupBody .signupSummaryText {
        min-height: 100vh !important;
        max-height: fit-content;
    }
    img.blueArrowText {
        display: none;
    }
    img.polygonalPics {
        height: 10rem !important;
        top: auto !important;
        bottom: 1rem !important;
    }
    .signupBody::-webkit-scrollbar {
        width: 4px;
    }
    img.pinkCurveLine {
        display: none;
    }
}