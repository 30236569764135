.agencyRegistration .form-control {
    border-color: #cccccc;
}

img.blueArrowText {
    position: absolute;
    top: 1rem;
    width: 15rem;
    left: 45%;
}

img.pinkCurveLine {
    position: absolute;
    top: 4.3rem;
    left: 15rem;
    z-index: 1;
    height: 2rem;
    transform: rotate(3deg);
}

img.polygonalPics {
    position: absolute;
    right: -7%;
    height: 15rem;
    top: calc(60% - 8rem);
    z-index: 1;
}

.position-overlay {
    position: relative;
    z-index: 9;
}

.signupRight .form-control {
    appearance: auto;
    height: 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem;
    }
    img.pinkCurveLine {
        top: 1.3rem !important;
        left: 12rem !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .signupBody .signupRight {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .signupBody .signupSummaryText {
        min-height: 50vh !important;
        height: auto !important;
    }
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem !important;
    }
    img.pinkCurveLine {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .signupBody .signupRight, .signupBody .signupSummaryText {
        min-height: 100vh !important;
        max-height: fit-content;
        height: auto !important;
    }
    img.blueArrowText {
        display: none;
    }
    img.polygonalPics {
        height: 10rem !important;
        top: auto !important;
        bottom: 1rem !important;
    }
    .signupBody::-webkit-scrollbar {
        width: 4px;
    }
    img.pinkCurveLine {
        display: none;
    }
}