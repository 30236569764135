.loginFooter {
    background-color: var(--primaryColor);
    height: 100px;
}

.loginBox > .d-block {
    width: calc(350px + 3rem);
}

.loginBox .error {
    background: #ea7783;
    border-radius: 4px;
    color: #fff !important;
    width: fit-content;
    padding: 2px 5px;
    font-size: .78rem !important;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .loginBox {
        max-width: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding-top: 15%;
    }
}

@media only screen and (max-width: 991px) {
    .loginBody h3.mb-4.pb-2.text-navy {
        text-align: center;
    }

    .loginBody button.btn.btn-primary {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .loginBox > .d-block {
        max-width: calc(350px + 3rem);
        width: auto;
        margin: 0 auto !important;
    }
}