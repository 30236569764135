.bidsView > .fullscreenWithTopBar {
    max-height: calc(100vh - 64px);
    overflow-y: overlay;
}

.bidsView .cardTitle {
    display: inline-flex;
}

/* .bidsView span.badge.bg-event {
    margin-right: 0.5rem !important;
} */

.bidsView .backArrow {
    position: absolute;
    left: 0;
}

.bidsSearch {
    position: relative;
    top: 0;
    z-index: 9;
    width: 100%;
}

.bidsSearch .input-group > .input-group-text {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-right-width: 1px !important;
}

.bidsSearch .form-control::placeholder {
    color: #777;
    font-size: .9rem;
}

.bidsCard {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.bidsCard > div.d-flex {
    width: calc(100% - 46px - 1rem);
}

.bidsCard .brandDetails {
    width: calc(100% - 30px);
}

.bidsCard .w-30px {
    width: 30px;
}

ul.nav.nav-tabs li.nav-item .nav-link {
    background: transparent;
}

.BidsList .tab-content .tab-pane .row {
    max-height: calc(100vh - 194px);
    overflow-y: overlay;
    overflow-x: hidden;
}

.BidsList .tab-content .tab-pane .row::-webkit-scrollbar {
    width: 4px;
}

.completeCollaboration .multiUpload .multiUploadBox {
    height: 100px;
    width: 100%;
    line-height: 95px;
}

.completeCollaboration .multiUpload input.form-control {
    height: 100px;
    width: 100%;
    opacity: 0;
}

.bidsCard:hover {
    border-color: var(--primaryColor) !important;
}

video {
    vertical-align: middle;
}

@media only screen and (max-width: 991px) {
    .BidsList .pageTitleContainer, .pageName {
        display: block;
        min-height: auto;
    }
    .BidsList .container-fluid .container {
        max-width: 100%;
    }
    .BidsList .mobileFooter, .bidsView .mobileFooter {
        display: none;
    }
    .BidsList .tab-content .tab-pane .row {
        max-height: calc(100vh - 181px);
    }
    .bidsView > .fullscreenWithTopBar {
        max-height: calc(100vh - 64px);
        overflow-y: overlay;
        padding-bottom: 3rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .BidsList .mobileFooter, .bidsView .mobileFooter {
        display: block;
    }

    .BidsList .pageTitleContainer, .pageName {
        display: none;
    }

    .bidsView .border-0.flex-row.mb-3.align-items-center.card {
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee !important;
        padding-right: 1.75rem;
    }

    .bidsView .col-12.col-md-6.col-lg-4 {
        padding-right: 0;
    }

    .bidsView .sectionTitle.text-truncate {
        text-align: center;
    }

    .bidsSearch {
        padding: 0 1rem 0.75rem;
        position: relative;
        border: 0;
    }

    .BidsList ul.nav.nav-tabs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
    }

    .BidsList .tab-content .tab-pane .row {
        max-height: calc(100vh - 230px);
    }

    .bidsView > .fullscreenWithTopBar > .container {
        max-width: 100%;
    }

    .bidsView > .fullscreenWithTopBar {
        padding-bottom: 1rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .homePage .cardScrollView p.text-muted {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}