.settingsArea .accordion-body {
    max-height: calc(100vh - 245px);
    overflow-y: overlay;
}

.settingsArea .accordion-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.settingsArea .accordion-body::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    width: 17px;
}

.settingsArea .accordion-body textarea::-webkit-scrollbar-track {
    background-color: transparent;
}

.settingsArea .accordion-body textarea::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    width: 17px;
}

.settingsArea .accordion-body > .border-bottom.mb-4:last-child {
    border: 0 !important;
    margin: 0 !important;
}

.settingsArea .accordion-body > .border-bottom.mb-4:last-child .form-group.mb-3:last-child {
    margin: 0 !important;
}

.fullscreenWithTopBar {
    overflow-y: overlay;
}

.userSettingsNav a {
    color: #4F4F4F !important;
    background-color: transparent !important;
}

.userSettingsNav a.active,
.nav.nav-tabs .userSettingsNav .nav-link.active {
    font-weight: 600;
    border-bottom: 0 !important;
}

.nav.nav-tabs .userSettingsNav .nav-link:hover, 
.nav.nav-tabs .userSettingsNav .nav-link:focus,
.nav.nav-tabs .userSettingsNav .nav-link {
    border-bottom: 0 !important;
}

.userSettingsNav a:hover {
    color: var(--primaryColor) !important;
}

.userSettingsNav a.active > span > svg > path {
    stroke: none;
    fill: #000;
}

input.fileUploadBtn {
    width: 36px;
    height: 36px;
    opacity: 0;
}

.adminSettings button.accordion-button {
    color: var(--primaryColor);
    background: var(--lightColor);
    border-color: var(--primaryColor) !important;
}

.adminSettings button.accordion-button:focus {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color) !important;
}

@media only screen and (max-width: 1200px) {
    .settingsArea {
        max-height: calc(100vh - 130px);
        overflow-y: overlay;
        min-height: calc(100vh - 130px);
    }
    .settingsArea::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .settingsArea .accordion-body {
        max-height: calc(100vh - 270px);
        overflow-y: overlay;
    }
    .fullscreenWithTopBar.userSettings > .container {
        padding-bottom: calc(80px + 1rem);
    }
}

@media only screen and (max-width: 991px) {
    .fullscreenWithTopBar {
        padding-bottom: 0 !important;
    }
    .fullscreenWithTopBar.userSettings > .container {
        max-width: 100%;
    }
    .settingsArea {
        max-height: calc(100vh - 127px);
        min-height: calc(100vh - 127px);
    }
    .settingsArea .accordion-body {
        max-height: calc(100vh - 262px);
    }
}

@media only screen and (max-width: 767px) {
    .fullscreenWithTopBar.userSettings {
        max-height: calc(100vh - 60px);
        overflow-x: hidden;
        min-height: calc(100vh - 60px);
    }
    .userSettings::-webkit-scrollbar {
        display: none;
    }
    .settingsArea {
        max-height: calc(100vh - 127px);
        min-height: calc(100vh - 127px);
        padding-bottom: 4rem;
    }
    .settingsArea .accordion-body {
        max-height: fit-content;
    }
    .fullscreenWithTopBar.userSettings .userSettingsNav {
        box-shadow: none !important;
        border: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        background: var(--secondaryColor) !important;
    }
    .fullscreenWithTopBar.userSettings > .container {
        padding-bottom: 0;
    }
    .fullscreenWithTopBar.userSettings .userSettingsNav:last-child {
        padding-top: 0 !important;
    }
    .fullscreenWithTopBar.userSettings .userSettingsNav > .bg-primary.rounded-top-xl {
        border-radius: 0 !important;
    }
    .nav.nav-tabs .userSettingsNav .nav-link {
        font-weight: 500;
    }
    .userSettings .tab-content>.active {
        background-color: var(--secondaryColor);
        position: fixed;
        top: 0;
        padding-top: 1.5rem;
        padding-bottom: 0;
        height: auto;
        width: 100%;
    }
    .userSettings .tab-content>.active::-webkit-scrollbar {
        display: none;
    }
    .userSettings .bg-white.rounded-xl {
        border-radius: 0 !important;
    }
    .userSettings .tab-content .bg-white.rounded-xl {
        border-radius: 0 !important;
        height: calc(100vh - 142px);
        border: 0 !important;
        overflow-y: overlay;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    .userSettings .tab-content .bg-white.rounded-xl::-webkit-scrollbar {
        display: none;
    }
    div#userSettings-tab-tabpane-termsOfUse .mb-4 > p {
        display: flex;
        flex-direction: column;
        width: calc(100% - 1.5rem);
    }
    .adminSettings nav#topBar, .settings nav#topBar {
        display: block;
        z-index: 0;
    }
    .adminSettings nav#topBar > .container-fluid,
    .settings nav#topBar > .container-fluid {
        display: flex;
    }
    .fullscreenWithTopBar.userSettings.container-fluid {
        background: var(--secondaryColor);
    }
}