.dashboard .fullscreenWithTopBar {
    background-color: var(--secondaryColor) !important;
}

.dashboard .dashboardTable {
    max-height: calc(100vh - 175px);
    overflow-y: overlay;
    min-height: calc(100vh - 175px);
}

.dashboard .dashboardTable table thead {
    position: sticky;
    top: 8px;
    background: var(--secondaryColor);
    z-index: 9;
    height: 30px;
}

.brandAction.dropdown .dropdown-menu {
    z-index: 2;
}

.dashboard .form-group .input-group .border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.dashboard .text-danger {
    color: #FF0000 !important;
}

.dashboard .card {
    border-color: #D2D2EB !important;
}

.dashboard .card .card-header {
    background-color: #E4E4F1;
}

.dashboard .table {
    border-collapse:separate;
    border-spacing: 0 8px;
}

.dashboard .table.border-transparent {
    border-color: transparent !important;
}

.dashboard .table.border-transparent thead th {
    padding: 0 0 0 0.75rem !important;
    font-weight: 500;
    font-size: .9rem;
    white-space: nowrap;
}

.dashboard .table.border-transparent thead th:last-child {
    padding-right: 0.75rem !important;
}

.dashboard .table.border-transparent tbody td {
    padding: 0.75rem 0 0.75rem 0.75rem !important;
}

.dashboard .table.border-transparent tbody td:last-child {
    padding-right: 0.75rem !important;
}

span.badge.bg-oldPlatform {
    background: #d4a0d2;
    color: #ffffff;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

.fade.modal-backdrop.show {
    width: 400px;
    right: 0;
    left: auto;
    display: none;
}

.fade.modal-backdrop.show {
    opacity: 0.2 !important;
}

.fade.right.modal.show {
    width: 400px;
    left: auto;
    right: 0;
}

span.badge.bg-other {
    background: #e9e9e9;
    color: #333333;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

span.badge.bg-lite {
    background: #bbbbbb;
    color: #333;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

span.badge.bg-pro {
    background: #B5ECFD;
    color: #333;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

span.badge.bg-enterprise {
    background: #FAE5C5;
    color: #333;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

span.badge.bg-draft {
    background: #EEEEEE;
    color: #585858;
    font-weight: 400;
    padding: 0.35rem 0.5rem;
    font-size: .8rem;
}

.brandAction .dropdown-toggle::after {
    display: none;
}

.brandAction .dropdown-item.active, 
.brandAction .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.dashboard .table.border-transparent tbody td.accordionTable {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 3.5rem !important;
}

.dashboard .table.border-transparent tbody td.accordionTable button.accordion-button {
    outline: 0 !important;
    box-shadow: none !important;
    background: #00000015 !important;
    color: var(--primaryColor);
    font-weight: 500;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: 1.4rem;
}

.dashboard .table.border-transparent tbody td.accordionTable .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

.dashboardOptions {
    display: flex;
    align-items: center;
}

#showAllToggle {
    padding-right: 0.75em;
}

@media only screen and (max-width: 1200px) {
    .dashboard .dashboardTable {
        max-height: calc(100vh - 142px);
        min-height: calc(100vh - 142px);
    }
    .dashboard .dashboardTable::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .dashboard .dashboardTable th:nth-of-type(2), .dashboard .dashboardTable td:nth-of-type(2), 
    .dashboard .dashboardTable th:nth-of-type(3), .dashboard .dashboardTable td:nth-of-type(3), 
    .dashboard .dashboardTable th:nth-of-type(9), .dashboard .dashboardTable td:nth-of-type(9) {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    #topBar, #topBar > .container-fluid {
        display: flex !important;
    }
    .dashboard .table.border-transparent thead th {
        white-space: normal;
        padding: 0 0 0 0.5rem !important;
    }
    .dashboard .table.border-transparent thead th:last-child {
        padding-right: 0.5rem !important;
    }
    .dashboard .table.border-transparent tbody td {
        padding: .5rem 0 .5rem .5rem !important;
    }
    .dashboard .table.border-transparent tbody td:last-child {
        padding-right: 0.5rem !important;
    }
    .dashboard .dashboardTable th:nth-of-type(2), .dashboard .dashboardTable td:nth-of-type(2), 
    .dashboard .dashboardTable th:nth-of-type(3), .dashboard .dashboardTable td:nth-of-type(3), 
    .dashboard .dashboardTable th:nth-of-type(5), .dashboard .dashboardTable td:nth-of-type(5), 
    .dashboard .dashboardTable th:nth-of-type(8), .dashboard .dashboardTable td:nth-of-type(8), 
    .dashboard .dashboardTable th:nth-of-type(9), .dashboard .dashboardTable td:nth-of-type(9) {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .dashboard .dashboardTable {
        max-height: calc(100vh - 142px);
        min-height: calc(100vh - 142px);
    }
    .dashboard .dashboardTable::-webkit-scrollbar {
        display: none;
    }
    .dashboard h1.pageTitle + .form-group {
        max-width: 50%;
        min-width: 150px;
    }
    .dashboard #topBar {
        display: block;
    }
    .dashboard #topBar > .container-fluid {
        display: flex;
    }
    .dashboard .table.border-transparent {
        padding-right: 0;
    }
    .dashboard .dashboardTable th:nth-of-type(2), .dashboard .dashboardTable td:nth-of-type(2), 
    .dashboard .dashboardTable th:nth-of-type(3), .dashboard .dashboardTable td:nth-of-type(3), 
    .dashboard .dashboardTable th:nth-of-type(4), .dashboard .dashboardTable td:nth-of-type(4), 
    .dashboard .dashboardTable th:nth-of-type(5), .dashboard .dashboardTable td:nth-of-type(5), 
    .dashboard .dashboardTable th:nth-of-type(6), .dashboard .dashboardTable td:nth-of-type(6), 
    .dashboard .dashboardTable th:nth-of-type(6), .dashboard .dashboardTable td:nth-of-type(7) {
        display: none;
    }
    .dashboard .dashboardTable th:nth-of-type(7), .dashboard .dashboardTable td:nth-of-type(8),
    .dashboard .dashboardTable th:nth-of-type(9), .dashboard .dashboardTable td:nth-of-type(9) {
        display: table-cell;
    }
}

@media only screen and (max-width: 576px) {
    .dashboard .dashboardTable th:nth-of-type(7), .dashboard .dashboardTable td:nth-of-type(8),
    .dashboard .dashboardTable th:nth-of-type(9), .dashboard .dashboardTable td:nth-of-type(9) {
        display: none;
    }
    .dashboard .dashboardTable th:nth-of-type(1), .dashboard .dashboardTable td:nth-of-type(1) {
        max-width: 200px;
    } 
}