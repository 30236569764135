.mobileFooter {
    position: fixed;
    width: 100%;
    height: 61px;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px -5px 10px #ccc;
}

.footerItem {
    padding: 10px 0 5px;
    font-size: .8rem;
    text-align: center;
    color: #A7A7A7;
}

.footerItem > span {
    display: block;
    font-size: .75rem;
}

.footerItem > svg {
    height: 24px;
}

.active > .footerItem > span {
    color: #000;
    font-weight: 500;
}

.active > .footerItem > svg > path:not(.footerItem.bids > svg > path),
.active > .footerItem > svg > circle:not(.footerItem.bids > svg > circle) {
    fill: var(--primaryColor);
}

.active > .footerItem.bids > svg > path,
.active > .footerItem.bids > svg > circle {
    stroke: var(--primaryColor);
}