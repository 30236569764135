.leftSideMenu {
    position: sticky;
    top: 0;
    min-height: calc(100vh - 112px);
}

.leftSideMenu a.active {
    color: #000;
    font-weight: 600;
}

.leftSideMenu a {
    color: #777;
    display: table;
    margin-bottom: 1rem;
    font-weight: 400;
}

span.adminNavName {
    color: #777;
    margin-left: 0.5rem;
    font-size: .9rem;
    font-weight: 400;
}

.adminFooter a.active span.adminNavName {
    color: #000;
    font-weight: 600;
}

.leftSideMenu a span svg {
    width: 18px;
    margin-bottom: 4px;
}

a > span.fill > svg > path,
a > span.fill > svg > circle {
    fill: #777;
}

a > span.stroke > svg > path,
a > span.stroke > svg > circle {
    stroke: #777;
}

a > span.fill.stroke > svg > path,
a > span.fill.stroke > svg > circle {
    stroke: #777;
    fill: #777;
}

.active > span.fill > svg > path,
.active > span.fill > svg > circle {
    fill: var(--primaryColor);
}

.active > span.stroke > svg > path,
.active > span.stroke > svg > circle {
    stroke: var(--primaryColor);
}

.active > span.fill.stroke > svg > path,
.active > span.fill.stroke > svg > circle {
    stroke: var(--primaryColor);
    fill: var(--primaryColor);
}

.adminFooterBtn {
    display: none;
    position: fixed;
    bottom: 0.75rem;
    background: #fff;
    border-radius: 2rem;
    left: 0.75rem;
    width: auto;
    box-shadow: 0 0 3rem rgba(0,0,1,0.5)!important;
    line-height: 1;
    padding: 0.75rem;
    border: 0;
    z-index: 9;
}

.adminFooter {
    display: block;
    position: fixed;
    bottom: calc(0.75rem + 60px);
    left: 0.75rem;
    width: auto;
    min-width: 180px;
    z-index: 99;
    background: #fff;
    border-top: 1px solid #dee2e6;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    border-radius: 1rem;
    text-wrap: nowrap;
}

.adminFooter a {
    text-wrap: nowrap;
    display: block;
    padding: 0.75rem 1rem 0.75rem 0.5rem;
    border-bottom: 1px solid #eee;
}

.adminFooter a:last-child {
    border-bottom: 0;
}

.adminFooter a span:first-child {
    width: 30px;
    display: inline-block;
    text-align: center;
}

.adminFooter a span:first-child svg {
    vertical-align: text-bottom;
}

.adminFooter a span.adminNavName {
    font-size: 1rem;
}

/* @media only screen and (max-width: 1200px) {    
    .leftSideMenu {
        position: relative;
        min-height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .leftSideMenu a {
        margin-bottom: 0;
    }
} */

@media only screen and (max-width: 1480px) {
    .leftSideMenu {
        display: none;
    }    
}

@media only screen and (max-width: 1323px) {    
    .adminFooter {
        display: block;
    }
}

@media (max-width: 768px) {    
    .adminFooter {
        display: block;
        min-width: auto;
    }
}

@media only screen and (max-width: 1323px) {    
    .adminFooterBtn {
        display: block;
    }
}

@media (max-width: 768px) {    
    .adminFooterBtn {
        display: block;
    }
}