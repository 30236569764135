:root {
    --background: #fff;
    --hoverColor: #006d6b;
    --textColor: #002651;
    --textColorHoverColor: #2b4e76;

    /* New Branding Colors */
    --primaryColor: #143232;
    --secondaryColor: #DCD7C8;
    --lightColor: #FAFAFA;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300;400;500;700&display=swap');

html {
    font-family: 'Roboto', sans-serif;
    tab-size: 4;
    line-height: normal;
    background-color: var(--secondaryColor) !important;
    scroll-behavior: smooth !important;
}

body {
    font-family: 'Roboto', sans-serif;
}

@font-face {
    font-family: 'Denim';
    src: url(./fonts/Denim/Denim-Regular.woff) format('woff'), url(./fonts/Denim/Denim-Regular.woff2) format('woff2');
}

.denimFont {
    font-family: 'Denim', sans-serif !important;
}

@font-face {
    font-family: 'Tragedy-Light';
    src: url(./fonts/Tragedy/NaNTragedyDisplay-Light.woff2) format('woff2');
}

.tragedyLightFont {
    font-family: 'Tragedy-Light', sans-serif !important;
}

@font-face {
    font-family: 'Tragedy-Regular';
    src: url(./fonts/Tragedy/NaNTragedyDisplay-Regular.woff2) format('woff2');
}

.tragedyRegularFont {
    font-family: 'Tragedy-Regular', sans-serif !important;
}

@font-face {
    font-family: 'Tragedy-Italic';
    src: url(./fonts/Tragedy/NaNTragedyText-Italic.woff2) format('woff2');
}

.tragedyItalicFont {
    font-family: 'Tragedy-Italic', sans-serif !important;
}

.homePage .btn {
    font-family: 'Tragedy-Italic', sans-serif !important;
    font-size: 1.2rem !important;
    padding: 0 0.35rem !important;
    line-height: 1.81rem !important;
}

.homePage .form-select {
    font-family: 'Tragedy-Italic', sans-serif !important;
    font-size: 1.25rem !important;
    padding-top: 0 !important;
    line-height: 1.81rem !important;
    padding-bottom: 0 !important;
}

.hide {
    display: none;
}

a, a:hover {
    color: var(--primaryColor);
    text-decoration: unset;
    cursor: pointer;
    font-weight: 500;
}

.screenshotSize {
    max-height: 500px;
}

.object-fit-cover {
    object-fit: cover !important;
}

.object-fit-contain {
    object-fit: contain !important;
}

.object-fit-contain {
    object-fit: contain;
}

.mw-75 {
    max-width: 75% !important;
}

.mw-90 {
    max-width: 90% !important;
}

.col-padding-bottom {
    padding-bottom: calc(var(--bs-gutter-x) * .5);
}

.col-padding-bottom:nth-of-type(3), 
.col-padding-bottom:nth-of-type(4) {
    padding-top: calc(var(--bs-gutter-x) * .5);
    padding-bottom: 0;
}

.col-padding-top {
    padding-top: calc(var(--bs-gutter-x) * .5);
}

.fullscreenWithTopBar {
    height: calc(100vh - 64px);
    width: 100%;
    position: relative;
    margin-top: 64px;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: var(--secondaryColor) !important;
}

.fullscreenWithTopBar.pageNotFound {
    height: 100vh;
    margin: 0;
}

.bg-gray {
    background-color: var(--lightColor) !important;
}

.border-gray {
    border: 1px solid var(--lightColor) !important;
}

.multiCarousel {
    display: flex;
    grid-gap: 1rem;
    gap: 1.5rem;
    overflow-x: hidden;
}

.multiCarousel::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.multiCarousel .card {
    width: 20%;
}

.multiCarousel .card .text-muted {
    color: #949494!important;
    letter-spacing: .25px;
}

.tableHeaderFixedBelt {
    position: absolute;
    background: var(--secondaryColor);
    width: calc(100% - 16.66666667% - 3rem - 20px);
    height: 8px;
    z-index: 9;
}

.cardImage {
    height: 222px;
    width: 100%;
    object-fit: cover;
    background-color: #666;
}

.sticky-top {
    position: absolute;
    top: 0;
    z-index: 1020;
    width: 100%;
}

select.form-control {
    appearance: auto;
}

.pageName {
    position: fixed;
    z-index: 99;
    top: 16px;
    font-size: 1rem;
    font-weight: 400;
    color: #888;
    width: 300px;
    left: calc(50% - 150px);
    text-align: center;
}

.pageTitleContainer {
    background-color: var(--primaryColor);
    width: 100%;
    min-height: 80px;
    color: var(--lightColor);
    position: relative;
    z-index: 9;
}

.pageTitle {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    text-size-adjust: 100%;
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    width: 17px;
    height: 4px;
}

*::-webkit-scrollbar-track {
    background-color: var(--secondaryColor);
    border-radius: 0;
}

*::-webkit-scrollbar-thumb {
    background: #8b8264;
    border-radius: 20px;
    border: 4px solid var(--secondaryColor);
}

.small, small {
    font-size: .875em !important;
}

label {
    display: block;
    margin-bottom: 3px;
    font-size: .875em;
}

input[type="checkbox"] {
    height: 16px;
    width: 16px;
    vertical-align: text-bottom;
}

.form-control, .input-group > .input-group-text {
    border-color: #ccc !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #555;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #555;
}

.input-group > .input-group-text {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-right: 0 !important;
}

.input-group > .input-group-text + .form-control {
    padding-left: 0 !important;
}

.input-group > .input-group-text + .form-control:focus {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.signupBody .input-group .btn {
    position: relative;
    z-index: 0;
}

.loginBody, .signupBody {
    height: calc(100vh - 100px);;
    background: var(--secondaryColor) !important;
}

.signupRight {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    position: relative;
}

.signupFooter {
    background-color: var(--primaryColor) !important;
    height: 100px;
}

.input-group > span.bg-white.border-end.input-group-text {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.wallInfo {
    max-height: 2.4rem;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 100%;
    white-space-collapse: break-spaces;
    text-wrap: wrap;
    font-size: .9rem;
    font-weight: 400;
    color: var(--primaryColor) !important;
    overflow: hidden;
}

.signupPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #eee;
}

#brand-signup-logo {
    width: 100%;
    max-width: 250px;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.cursor-pointer {
    cursor: pointer;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--secondaryColor);
}

.spinner > svg {
    height: 100px;
    width: 100px;
}

.spinner > svg > .path {
    animation: spin-animation 1.5s infinite ease-in-out;
    transform-origin: center;
}

.sectionTitle {
    color: var(--primaryColor);
    font-size: 1.05rem;
    font-weight: 500;
}

.cardTitle {
    font-weight: 500;
    font-size: .9rem;
    color: var(--primaryColor);
}

span.badge.bg-event {
    background: #e8e8e8;
    font-weight: 400;
    color: #555;
    font-size: .75rem;
}

span.badge.bg-primary {
    background: var(--primaryColor) !important;
}

span.badge.rounded-pill {
    padding: 0.25rem 0.5rem 0.15rem;
}

.badge.rounded-pill.bg-completed {
    background: #BFE0FF !important;
    font-weight: 400;
    color: #29455F !important;
    padding: 0.25rem 0.5rem 0.15rem;
}

.badge.rounded-pill.bg-cancelled {
    background: #ffd334 !important;
    font-weight: 400;
    color: #676820 !important;
    padding: 0.25rem 0.5rem 0.15rem;
}

.smaller {
    font-size: .75rem;
}

.btn-primary {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
    color: var(--lightColor) !important;
}

.btn-outline-primary {
    background-color: #fff;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
    color: #fff;
}

.btn-secondary {
    background-color: var(--secondaryColor) !important;
    border-color: var(--secondaryColor) !important;
    color: var(--primaryColor) !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: var(--secondaryColor) !important;
    border-color: var(--secondaryColor) !important;
    color: var(--primaryColor) !important;
}

.btn-outline-danger {
    background-color: #fff;
    border-color: #dc3545;
    color: #dc3545;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.btn.disabled, 
.btn:disabled, 
fieldset:disabled .btn {
    color: #fff;
    background-color: #a2a2a2;
    border-color: #a2a2a2;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-blue {
    color: var(--primaryColor) !important;
}

.text-navy {
    color: var(--primaryColor) !important;
}

.text-primary {
    color: var(--primaryColor) !important;
}

.text-secondary {
    color: var(--secondaryColor) !important;
}

.text-light {
    color: var(--lightColor) !important;
}

.text-muted {
    color: #3a4045 !important;
}

.chatContainer .border-bottom {
    border-bottom-color: #bfb077 !important;
}

.badge.rounded-pill.text-dark.bg-light {
    background: #ddd !important;
    font-weight: 400;
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
}

.badge.rounded-pill.text-dark.bg-success,
.badge.rounded-pill.bg-success {
    background: #C9FBDA !important;
    font-weight: 400;
    color: #01591F !important;
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
}

.badge.rounded-pill.text-dark.bg-danger {
    background: #FFCCCC !important;
    font-weight: 400;
    color: #7B0000 !important;
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
}

.badge.rounded-pill.text-dark.bg-warning {
    background: #FFDDBF !important;
    font-weight: 400;
    color: #945A02 !important;
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
}

.badge.bg-info {
    width: fit-content;
}

.z-index-9 {
    z-index: 9 !important;
}

.twoLineTruncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2rem;
    width: 100%;
    white-space: break-spaces;
    line-height: 1rem;
    font-size: .75rem;
}
  
@keyframes spin-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

#topBar > .mobileHeader, .mobileFooter {
    display: none;
}

table th, table td {
    vertical-align: middle;
}

.moreVertical > .dropdown-toggle {
    background: url('./images/more-vertical.svg') no-repeat;
    background-position: center;
}

.moreVertical > .dropdown-toggle::before {
    border: 0 !important;
}

.moreVertical button {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.card img.cardImage,
.imagesBoxView img.w-100 {
    background: url('./images/tail-spin.svg') no-repeat;
    width: 100%;
    object-fit: cover;
    background-color: #666;
    background-size: cover;
    background-position: center;
    text-indent: -10000px;
}

#imageViewer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000000dd;
}

/* #imageViewer .closeImageViewer {
    position: absolute;
    bottom: 1.2rem;
    z-index: 10;
    left: calc(50% - 55px);
    width: 110px;
} */

#imageViewer .carouselControl {
    position: absolute;
    bottom: 1.2rem;
    z-index: 10;
    left: calc(50% - 75px);
    width: 150px;
    display: flex;
    gap: 1rem;
}

#imageViewer .carousel-inner {
    padding-top: calc(100px + 2rem);
}

#imageViewer .carousel-item img {
    max-width: 75%;
    height: calc(100vh - 200px);
    margin: 0 auto;
}

#imageViewer .carousel-item video {
    max-width: 75%;
    height: calc(100vh - 200px);
    margin: 0 auto;
}

.campaignDetail .fade:not(.show) {
    opacity: 1;
    display: none;
}

.campaignDetail .fade.show {
    display: block;
}

#imageViewer .carousel-control-next, 
#imageViewer .carousel-control-prev, 
#imageViewer .carousel-indicators {
    display: flex !important;
}

.myCampaignsTableImage {
    background: url('./images/tail-spin.svg') no-repeat;
    width: 70px;
    min-width: 70px;
    height: 50px;
    min-height: 50px;
    object-fit: cover;
    background-color: #666;
    background-size: cover;
    background-position: center;
    text-indent: -10000px;
}

.bubbleRight img.rounded {
    width: 30px;
    object-fit: cover;
    background-color: #ddd;
    background-size: cover;
    background-position: center;
    text-indent: -10000px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
}

.skeleton-box {
    position: relative;
    overflow: hidden;
    background-color: #dddbdd !important;
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 2s infinite;
            animation: shimmer 2s infinite;
    content: "";
}

.skeleton-box > div,
.skeleton-box > img.rounded {
    opacity: 0;
}

h6.skeleton-box {
    color: #ddd !important;
}

a.skeleton-box {
    color: #ddd !important;
}

.rounded-xl {
    border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-xl {
    border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl) !important;
}

.rounded-top-xl {
    border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0 !important;
}

.campaignDetail .rounded-top-xl {
    border-radius: 2rem 2rem 0 0 !important;
}

.nav.nav-tabs {
    border-bottom-color: var(--lightColor) !important;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: var(--primaryColor) !important;
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: var(--secondaryColor) !important;
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: var(--lightColor) !important;
}

.attachedImages {
    display: flex;
    position: absolute;
    z-index: 9;
    top: -70px;
    gap: 0.5rem;
    right: 10px;
}

.attachedImages .imageWrapper {
    position: relative;
    display: inline-flex;
}

.attachedImages .imageWrapper .overlayTopRight {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.fullscreenWithTopBar .spinner-container {
    position: relative;
    height: calc(100vh - 250px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.fullscreenWithTopBar .spinner-container .spinner {
    width: 100%;
    height: auto;
    z-index: 99;
    position: unset;
    background-color: transparent;
}

.filters .dropdown > button.btn-light {
    background-color: #fff;
    border-radius: 0.25rem;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.desktopView {
    display: block;
}

.mobileView {
    display: none;
}

.desktopFlex {
    display: flex;
}

.mobileFlex {
    display: none;
}

.noAccessImg {
    max-width: 500px;
    width: 100%;
}

.reachedBottom {
    position: sticky;
    width: -webkit-fill-available;
    top: 70vh;
    font-weight: 300;
    color: var(--primaryColor);
    font-size: 1.25rem;
}

#veryBad {
    background-color: #AB0000 !important;
}

#bad {
    background-color: #AB4B00 !important;
}

#neutral {
    background-color: #AB9600 !important;
}

#good {
    background-color: #6BAB00 !important;
}

#veryGood {
    background-color: #00AB0D !important;
}

.order-tracking{
	text-align: center;
	width: 33.33%;
	position: relative;
	display: block;
}
.order-tracking .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	border: 0px solid #AFAFAF;
	background-color: #f7be16;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 10px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.order-tracking.completed .is-complete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 6px;
	opacity: 1;
}
.order-tracking p {
	color: #A4A4A4;
	font-size: 14px;
	margin-bottom: 0;
	line-height: 20px;
}
.order-tracking p span{font-size: 14px;}
.order-tracking.completed p{color: #000;}
.order-tracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #f7be16;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.order-tracking:first-child:before { 
    display: none;
}
.order-tracking.completed:before {
    background-color: #27aa80;
}

@media only screen and (max-height: 900px), (max-width: 1200px) {
    .filters .show.dropdown > .dropdown-menu.show {
        max-height: 50vh;
        overflow-y: auto;
    }

    .filters .show.dropdown > .dropdown-menu.show::-webkit-scrollbar {
        width: 14px;
        height: 0px;
    }

    .filters .show.dropdown > .dropdown-menu.show::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 22px;
    }

    .filters .show.dropdown > .dropdown-menu.show::-webkit-scrollbar-thumb {
        border: 4px solid var(--background);
    }
}

.completeCollaboration.modal-body p span img {
    margin: 0 0 0 .25rem;
    border-right: 1px solid #ccc;
    padding-right: .25rem;
    width: calc(16px + 0.25rem);
}

.completeCollaboration.modal-body p span img:nth-last-of-type(1) {
    border-right: 0;
}

@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/* @media only screen and (min-width: 1921px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1920px;
    }
} */

@media only screen and (max-width: 1480px) {
    .pageName {
        display: none;
    }
    .leftSideMenuShift {
        display: none;
    }
    .col-xl-12.col-xxl-10.px-0 {
        width: 100%;
    }
    .dashboard .table.border-transparent thead th {
        white-space: normal;
    }
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1400px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1322px) {
    .loginBody, .signupBody {
        height: calc(100vh - 154px);
    }
    .signupRight {
        max-height: calc(100vh - 154px);
    }
    .signupFooter {
        background-color: var(--primaryColor) !important;
        min-height: 154px;
        height: auto;
    }
    .footerBrandLogo {
        margin-bottom: 1.5rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .signupBody {
        height: 100vh;
        padding-bottom: 0 !important;
        overflow-x: hidden !important;
    }
    .signupBody .signupRight {
        min-height: auto !important;
        max-height: initial !important;
    }
}

@media only screen and (max-width: 1323px) {
    .leftSideMenuShift {
        display: block;
    }
    .leftSideMenuShift.pt-xl-4.pb-xl-4 {
        padding: 0 !important;
    }
    .dashboard .table.border-transparent thead th {
        white-space: nowrap;
    }    
    .tableHeaderFixedBelt {
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .multiCarousel .card {
        width: 25%;
        min-width: calc(25% - 0.75rem);
    }
    .multiCarousel::-webkit-scrollbar {
        display: none;
    }
    .fullscreenWithTopBar .container {
        max-width: 100%;
    } 
    .fullscreenWithTopBar .tabViewChange .container-fluid .container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 991px) {
    .loginBody {
        height: 100vh;
        background: #fff;
        overflow-y: overlay;
        padding-bottom: 0 !important;
        position: absolute;
        top: 0;
        bottom: 0;
        text-align: center;
    }
    .loginBody .loginBox {
        height: 75% !important;
        border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0 !important;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    .signupSelection > .row, .loginBody > .row {
        margin: 0 !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    #brand-signup-logo {
        padding: 0 30px;
    }
    .container-fluid:not(nav > .container-fluid) {
        padding-bottom: 20px;
    }
    .desktopView {
        display: none;
    }
    .mobileView {
        display: block;
    }   
    .desktopFlex {
        display: none;
    }    
    .mobileFlex {
        display: flex;
    } 
    .fullscreenWithTopBar > .row > .border-end.bg-white {
        border: 0 !important;
    }
    *::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@media only screen and (max-width: 990px) {
    body, html {
        background-color: var(--lightColor);
        padding-bottom: 0;
    }    
    .mobileFooter {
        display: block;
    }
    #brand-signup-logo {
        margin: 50px auto;
        padding: 0;
        max-width: 200px;
    }
    #topBar {
        height: auto;
        position: relative;
        display: none;
        padding: 0;
    }
    #topBar > .container-fluid {
        display: none;
    }
    #topBar > .mobileHeader {
        padding: 2rem;
        background: #F2F2F7 !important;
    }
    .fullscreenWithTopBar {
        position: relative;
        overflow-x: hidden;
        top: 0;
        height: auto;
        min-height: auto;
        margin: 0;
        padding: 0;
        background-color: transparent;
    }
    .pageTitleContainer, .pageName {
        display: none;
    }
    .seeAllLink {
        font-size: 1rem;
    }
    .multiCarousel .card {
        width: 33%;
        min-width: calc(33% - 0.75rem);
    }
    .card-body {
        padding: .75rem;
    }
    .card-body .mb-2 {
        margin-bottom: .75rem !important;
    }
    .card-body .me-2 {
        margin-right: .75rem !important;
    }
    .cardImage {
        height: 130px;
    }
    .blackGradient {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        height: 100%;
        max-height: 40vh;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, .30));
        pointer-events: none;
    }
    
    #brand-signup-logo {
        max-width: 150px;
    }

    .container-fluid {
        padding: 0px;
    }

    .p-4.rounded {
        border-radius: 0px !important;
        padding: 1.5rem 1rem !important;
    }

    .pageDataCount {
        font-size: .75rem;
        display: flex;
        align-items: center;
    }
    
    h1.pageTitle {
        font-size: 1.2rem;
        padding-left: 0.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .loginBody, .signupBody {
        min-height: 100vh !important;
        overflow-x: hidden;
        padding: 0 !important;
    }
    .campaignDetail .rounded-top-xl {
        border-radius: 0 !important;
    }
    .multiCarousel {
        overflow-x: overlay;
        scroll-behavior: smooth;
    }
    .multiCarousel .card {
        width: 200px;
        min-width: 200px;
    }
    .loginBody > .row > .loginBox {
        padding-top: 3rem!important;
    }
    .tableHeaderFixedBelt {
        width: calc(100% - 1.5rem);
    }
}

@media only screen and (max-width: 576px) {
    h1.pageTitle {
        font-size: 1.1rem;
        padding-left: 0.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}