/* #applicant-list-container {
    height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    top: 64px;
    overflow: auto;
    background-color: #fff;
}

.applicant-avatar {
    width: 2rem;
}

.applicant-page-control {
    bottom: 0px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.applicant-page-control-container {
    bottom: 0px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #f5f5f5;
    padding: 1rem 1rem 0;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px 0 #666;
}

.applicant-page-control-cards {
    margin-bottom: 15px;
    margin-left: 15px;
}

.accordion.accordion-flush .card .card-header {
    padding: 0.65rem;
}

.accordion.accordion-flush .readMoreBtn {
    padding: 0;
    height: 31px;
}

@media only screen and (max-width: 767px) {
    .applicantEmail {
        margin-bottom: 1rem;
    }
} */

.pendingApplications .fullscreenWithTopBar {
    background-color: var(--secondaryColor) !important;
}

.pendingApplications .form-group .input-group .border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.pendingApplications .text-danger {
    color: #FF0000 !important;
}

.pendingApplications .card {
    border-color: #D2D2EB !important;
}

.pendingApplications .card .card-header {
    background-color: #f9f3dd;
}

.pendingApplications .applicationsList {
    max-height: calc(100vh - 260px);
    overflow-y: overlay;
    overflow-x: hidden;
    min-height: calc(100vh - 290px);
}

.pendingApplications .reachedBottom {
    top: calc(100vh - 400px);
}

.pendingApplications .applicationsList .card .card-text span.me-2::after {
    content: '●';
    padding-left: 0.5rem;
}

.fade.modal-backdrop.show {
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;
    display: block !important;
}

img.blueTick {
    background: #fff;
    position: absolute;
    margin-left: 14px;
    width: 12px;
    margin-top: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    height: 12px;
}

.socialInput {
    width: 75%
}

.applicationHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

img.editSocial {
    cursor: pointer;
    height: 16px;
    margin-left: 1rem;
}

@media only screen and (max-width: 1200px) {
    .pendingApplications .applicationsList {
        max-height: calc(100vh - 297px);
    }
    .pendingApplications .applicationsList::-webkit-scrollbar {
        width: 4px;
    }
    .pendingApplications .position-fixed.end-0.bottom-0.shadow.container-fluid {
        bottom: 0px !important;
        border-top: 1px solid #dee2e6;
        padding: 0;
    }
}

@media only screen and (max-width: 991px) {
    .pendingApplications .applicationsList {
        max-height: calc(100vh - 185px);
        min-height: calc(100vh - 185px);
    }
    .pendingApplications #topBar {
        display: block;
    }
    .pendingApplications #topBar > .container-fluid {
        display: flex;
    }
}