.influencerRegistration .form-control {
    border-color: #cccccc;
}

img.blueArrowText {
    position: absolute;
    top: 1rem;
    width: 15rem;
    left: 45%;
}

img.pinkCurveLine {
    position: absolute;
    top: 4.3rem;
    left: 15rem;
    z-index: 1;
    height: 2rem;
    transform: rotate(3deg);
}

img.polygonalPics {
    position: absolute;
    right: -7%;
    height: 15rem;
    top: calc(60% - 8rem);
    z-index: 1;
}

.position-overlay {
    position: relative;
    z-index: 9;
}

.handleAlert {
    z-index: 10;
    overflow-wrap: break-word;
}

.signupInfluencer {
    height: calc(100vh - 70px);
    overflow-y: overlay;
    overflow-x: hidden;
    background: var(--secondaryColor);
}

.signupInfluencer .carousel-caption {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    color: #fff;
    text-align: center;
}

.signupInfluencer .carousel-indicators {
    bottom: -5%;
    z-index: 0;
}

.signupInfluencer .carousel-indicators [data-bs-target] {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: var(--primaryColor);
    border: 0;
}

.influencerStep {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 9;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 100vh;
}

.influencerStep .progressInfluencer {
    height: 10px;
    width: 50%;
    margin: 0 auto;
}

.influencerStep .progressInfluencer .progress-bar {
    background-color: var(--primaryColor);
}

.influencerStep ul li::marker {
    color: var(--secondaryColor);
}

.influencerStep .bg-gray {
    background: #F6F3F0;
}

.influencerStep label.form-check-label {
    margin-bottom: 0;
    padding-top: 0;
}

.influencerStep .form-check {
    padding-top: 0.25rem;
}

.influencerStep .btn-group {
    display: block;
}

.influencerStep .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.influencerStep .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
}

.influencerStepBody {
    height: calc(100vh - 62px);
    overflow-y: overlay;
    scroll-behavior: smooth;
}

.influencerStepBody::-webkit-scrollbar {
    width: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem;
    }
    img.pinkCurveLine {
        top: 1.3rem !important;
        left: 12rem !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .signupBody .signupSummaryText {
        min-height: 55vh !important;
        height: auto !important;
    }
    img.polygonalPics {
        top: auto !important;
        bottom: 1rem !important;
    }
    img.pinkCurveLine {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .signupBody .signupRight, .signupBody .signupSummaryText {
        min-height: 100vh !important;
        max-height: fit-content;
    }
    img.blueArrowText {
        display: none;
    }
    img.polygonalPics {
        height: 10rem !important;
        top: auto !important;
        bottom: 1rem !important;
    }
    .signupBody::-webkit-scrollbar {
        width: 4px;
    }
}