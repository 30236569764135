.hide {
    display: none;
}

.show {
    display: block;
}

.brands .fullscreenWithTopBar,
.brands .bg-gray {
    background-color: var(--secondaryColor) !important;
}

.brandSettings .fullscreenWithTopBar {
    background: var(--secondaryColor);
}

.brandSettings .css-3w2yfm-ValueContainer {
    padding-left: 0;
    margin-top: 10px;
}

.brandSettings .border-top {
    border-top: 1px solid #F1F1F1 !important;
}

.brandSettingsNav a {
    color: #4F4F4F;
    background-color: transparent !important;
}

.brandSettingsNav a.active,
.nav.nav-tabs .brandSettingsNav .nav-link.active {
    font-weight: 600;
    border-bottom: 0 !important;
}

.nav.nav-tabs .brandSettingsNav .nav-link:hover, 
.nav.nav-tabs .brandSettingsNav .nav-link:focus,
.nav.nav-tabs .brandSettingsNav .nav-link {
    border-bottom: 0 !important;
}

.brandSettingsNav a:hover {
    color: var(--primaryColor);
}

.brandSettingsNav a.active > span > svg > path {
    stroke: none;
    fill: #000;
}

.brandSettingsNav .text-danger {
    color: #E50000 !important;
}

.brandSettingsNav.bg-primary {
    background-color: var(--primaryColor) !important;
}

.tags.tags-info {
    background: #DAEDFF !important;
    color: #29455F;
    display: block;
    font-weight: 500;
}

.brands .brandsTable {
    max-height: calc(100vh - 225px);
    overflow-y: overlay;
    min-height: calc(100vh - 255px);
}

.brands .brandsTable table thead {
    position: sticky;
    top: 8px;
    background: var(--secondaryColor);
    z-index: 9;
    height: 30px;
}

.brands .table,
.brandsHistory table.table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.brands .table.border-transparent,
.brandsHistory table.border-transparent {
    border-color: transparent !important;
}

.brands .table.border-transparent thead th {
    padding: 0 1rem !important;
    font-weight: 500;
    font-size: .9rem;
}

.brands .table.border-transparent tbody td {
    padding: 1rem !important;
    background-color: white;
}

.brands .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
}

.brands .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.brandsHistory .table.border-transparent tbody td {
    padding: .5rem !important;
    border: 1px solid #F1F1F1;
    border-right-width: 0;
    border-left-width: 0;
}

.brandsHistory .table.border-transparent thead th {
    white-space: nowrap;
}

.brandsHistory .table.border-transparent tbody td div.text-nowrap.text-truncate {
    max-width: 310px;
}

.brandsHistory .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    border-left-width: 1px;
}

.brandsHistory .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
    border-right-width: 1px;
}

.brandsHistory {
    max-height: fit-content;
    overflow-y: visible;
    overflow-x: hidden;
}

.brandsHistory::-webkit-scrollbar {
    width: 4px;
}

.input-group > span.bg-white.border-end.input-group-text {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.brandsTable .mt-n5 {
    margin-top: -1.35rem !important;
}

.form-floating > select {
    outline: 0 !important;
    box-shadow: none !important;
}

.customFloating {
    position: relative;
}

.customFloating label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    opacity: .65;
    font-size: .75rem;
}

.customFloating .css-13cymwt-control {
    line-height: 1.25;
    border-radius: 0!important;
    border-bottom: 1px var(--bs-border-style) var(--bs-border-color)!important;
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    padding: 1.625rem 0 0 0.75rem !important;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background: transparent !important;
}

.customFloating .css-1fdsijx-ValueContainer {
    padding: 0 !important;
}

.customFloating .css-1nmdiq5-menu {
    z-index: 10;
    display: block;
}

.customFloating .css-t3ipsp-control {
    padding-top: 1.625rem !important;
    padding-left: 0.75rem !important;
    background-color: transparent;
    border-radius: 0 !important;
    border-bottom: 1px var(--bs-border-style) var(--bs-border-color)!important;
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.customFloating .css-1dimb5e-singleValue {
    margin-left: 0;
}

.dashboardContent {
    height: calc(100vh - 155px);
    overflow-y: overlay;
}

.brandCommunity {
    height: calc(100vh - 154px);
    overflow-y: overlay;
}

.brandCommunity .panel .delete-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

@media only screen and (max-width: 1400px) {
    .dashboardContent {
        height: calc(100vh - 195px);
    }
}

@media only screen and (max-width: 1200px) {
    .brands .brandsTable {
        max-height: calc(100vh - 191px);
        min-height: calc(100vh - 191px);
    }
    .brands .brandsTable::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .brands .brandsTable th:nth-of-type(3), .brands .brandsTable td:nth-of-type(3), 
    .brands .brandsTable th:nth-of-type(4), .brands .brandsTable td:nth-of-type(4),
    .brands .brandsTable th:nth-of-type(6), .brands .brandsTable td:nth-of-type(6) {
        display: none;
    } 
    .brands .table.border-transparent thead th {
        white-space: normal;
        padding: 0 0 0 0.5rem !important;
    }
    .brands .table.border-transparent thead th:last-child {
        padding-right: 0.5rem !important;
    }
    .brands .table.border-transparent tbody td {
        padding: .5rem 0 .5rem .5rem !important;
    }
    .brands .table.border-transparent tbody td:last-child {
        padding-right: 0.5rem !important;
    }
    .brandSettings .brandsHistory table th:nth-of-type(5), 
    .brandSettings .brandsHistory table td:nth-of-type(5) {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .brands .brandsTable {
        max-height: calc(100vh - 135px);
        min-height: calc(100vh - 135px);
    }
    .brands .brandsTable th:nth-of-type(5), .brands .brandsTable td:nth-of-type(5) {
        display: none;
    } 
    .brandSettings .brandsHistory table th:nth-of-type(6), 
    .brandSettings .brandsHistory table th:nth-of-type(7), 
    .brandSettings .brandsHistory table td:nth-of-type(6), 
    .brandSettings .brandsHistory table td:nth-of-type(7) {
        display: none;
    }
    .dashboardContent {
        height: calc(100vh - 165px);
    }
}

@media only screen and (max-width: 767px) {
    .brands .brandsTable {
        max-height: calc(100vh - 135px);
        min-height: calc(100vh - 135px);
    }
    .brands .brandsTable th:nth-of-type(2), .brands .brandsTable td:nth-of-type(2) {
        display: none;
    } 
    .brands #topBar {
        display: block;
    }
    .brands #topBar > .container-fluid {
        display: flex;
    }
    .brandSettings .tab-content>.active {
        background-color: #F2F2F7;
        position: fixed;
        top: 0;
        padding-top: 1.5rem;
        padding-bottom: 0;
        height: 100%;
        min-height: 100vh;
        overflow-y: overlay;
        width: 100%;
        z-index: 99;
    }
    .brandSettings .tab-content>.active::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .brandSettings .tab-content>.active .bg-white.rounded-xl {
        border-radius: 0 !important;
        min-height: calc(100vh - 82px);
        border: 0 !important;
    }
    .brandSettings .brandsHistory table th:nth-of-type(4), 
    .brandSettings .brandsHistory table th:nth-of-type(5), 
    .brandSettings .brandsHistory table td:nth-of-type(4), 
    .brandSettings .brandsHistory table td:nth-of-type(5) {
        display: none;
    }
    .dashboardContent {
        height: calc(100vh - 145px);
    }
}

@media only screen and (max-width: 576px) {
    .brands .brandsTable th:nth-of-type(7), .brands .brandsTable td:nth-of-type(7) {
        display: none;
    }
    .brands .brandsTable th:nth-of-type(1), .brands .brandsTable td:nth-of-type(1) {
        max-width: 200px;
    } 
    .dashboardContent {
        height: calc(100vh - 140px);
    }
}

.metricsButton .btn-group {
    display: block;
}

.metricsButton .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.metricsButton .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
}

.metricsButton .btn-group {
    display: block;
}

.metricsButton .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.metricsButton .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
}