.profiles .fullscreenWithTopBar,
.profiles .bg-gray {
    background-color: var(--secondaryColor) !important;
}

.profiles .filters .dropdown > button.btn-light {
    background-color: #fff;
    border-radius: 0.25rem;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.profiles .influencersTable {
    max-height: calc(100vh - 168px);
    overflow-y: overlay;
    min-height: calc(100vh - 168px);
}

.profiles .influencersTable table thead {
    position: sticky;
    top: 8px;
    background: var(--secondaryColor);
    z-index: 9;
    height: 30px;
}

.influencerSettings .fullscreenWithTopBar {
    background: #F6F3F0;
}

.influencerSettings .border-top {
    border-top: 1px solid #F1F1F1 !important;
}

.influencerSettingsNav a {
    color: var(--primaryColor);
}

.influencerSettingsNav a.active {
    font-weight: 600;
}

.influencerSettingsNav a:hover {
    color: var(--primaryColor);
}

.influencerSettingsNav a.active > span > svg > path {
    stroke: none;
    fill: #000;
}

.influencerSettingsNav .text-danger {
    color: #E50000 !important;
}

.influencerSettingsNav.bg-primary {
    background-color: var(--primaryColor) !important;
}

.profiles .table,
.influencerHistory .table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.profiles .table.border-transparent,
.influencerHistory .table.border-transparent {
    border-color: transparent !important;
}

.profiles .table.border-transparent thead th {
    padding: 0 1rem 0 0 !important;
    font-weight: 500;
    font-size: .9rem;
}

.profiles .table.border-transparent thead th:first-child {
    padding-left: 1rem !important;
}

.profiles .table.border-transparent tbody td {
    padding: 1rem 1rem 1rem 0 !important;
    background-color: white;
}

.profiles .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    padding-left: 1rem !important;
}

.profiles .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.influencerHistory .table.border-transparent tbody td {
    padding: .5rem !important;
    border: 1px solid #F1F1F1;
    border-right-width: 0;
    border-left-width: 0;
}

.influencerHistory .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    border-left-width: 1px;
}

.influencerHistory .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
    border-right-width: 1px;
}

.influencerHistory {
    max-height: fit-content;
    overflow-y: visible;
    overflow-x: hidden;
}

.input-group > span.bg-white.border-end.input-group-text {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.influencersTable .mt-n5 {
    margin-top: -1.35rem !important;
}

.form-floating > select {
    outline: 0 !important;
    box-shadow: none !important;
}

.customFloating {
    position: relative;
}

.customFloating label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    opacity: .65;
    font-size: .75rem;
}

.customFloating .css-13cymwt-control {
    line-height: 1.25;
    border-radius: 0!important;
    border-bottom: 1px var(--bs-border-style) var(--bs-border-color)!important;
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    padding: 1.625rem 0 0 0.75rem !important;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background: transparent !important;
}

.customFloating .css-1fdsijx-ValueContainer {
    padding: 0 !important;
}

.customFloating .css-1nmdiq5-menu {
    z-index: 10;
    display: block;
}

.customFloating .css-t3ipsp-control {
    padding-top: 1.625rem !important;
    padding-left: 0.75rem !important;
    background-color: transparent;
    border-radius: 0 !important;
    border-bottom: 1px var(--bs-border-style) var(--bs-border-color)!important;
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.customFloating .css-1dimb5e-singleValue {
    margin-left: 0;
}

.customFloating .css-1u9des2-indicatorSeparator {
    display: none;
}

.css-b62m3t-container {
    z-index: auto;
}

.customHeaderMultiSelect .css-13cymwt-control,
.customHeaderMultiSelect .css-t3ipsp-control {
    margin: 0 8px !important;
    min-width: 220px !important;
}

@media only screen and (max-width: 1200px) {
    .profiles .influencersTable {
        max-height: calc(100vh - 242px);
        min-height: calc(100vh - 242px);
    }
    .profiles .influencersTable::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .profiles .influencersTable th:nth-of-type(3), .profiles .influencersTable td:nth-of-type(3), 
    .profiles .influencersTable th:nth-of-type(4), .profiles .influencersTable td:nth-of-type(4) {
        display: none;
    }
    .profiles .table.border-transparent thead th {
        white-space: normal;
        padding: 0 0 0 0.5rem !important;
    }
    .profiles .table.border-transparent thead th:last-child {
        padding-right: 0.5rem !important;
    }
    .profiles .table.border-transparent tbody td {
        padding: .5rem 0 .5rem .5rem !important;
    }
    .profiles .table.border-transparent tbody td:last-child {
        padding-right: 0.5rem !important;
    }
    .influencerSettings > .fullscreenWithTopBar > .container {
        padding-bottom: 2rem;
    }
    .influencerHistory table th:nth-of-type(1), 
    .influencerHistory table td:nth-of-type(1) {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .profiles .influencersTable {
        max-height: calc(100vh - 187px);
        min-height: calc(100vh - 187px);
    } 
    .profiles .influencersTable th:nth-of-type(2), .profiles .influencersTable td:nth-of-type(2) {
        display: none;
    }
    .profiles .influencersTable th:nth-of-type(3), .profiles .influencersTable td:nth-of-type(3), 
    .profiles .influencersTable th:nth-of-type(4), .profiles .influencersTable td:nth-of-type(4) {
        display: table-cell;
    }
    .influencerSettings > .fullscreenWithTopBar > .container {
        max-width: 100%;
    }
    .influencerHistory table th:nth-of-type(1), 
    .influencerHistory table th:nth-of-type(4), 
    .influencerHistory table td:nth-of-type(1), 
    .influencerHistory table td:nth-of-type(4) {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .profiles .influencersTable {
        max-height: calc(100vh - 185px);
        min-height: calc(100vh - 185px);
    }
    .profiles .influencersTable th:nth-of-type(2), .profiles .influencersTable td:nth-of-type(2), 
    .profiles .influencersTable th:nth-of-type(3), .profiles .influencersTable td:nth-of-type(3),
    .profiles .influencersTable th:nth-of-type(4), .profiles .influencersTable td:nth-of-type(4) {
        display: none;
    } 
    .profiles .influencersTable th:nth-of-type(5), .profiles .influencersTable td:nth-of-type(5) {
        display: table-cell;
    }
    .profiles .table.border-transparent thead th {
        white-space: nowrap;
    }
    .profiles #topBar {
        display: block;
    }
    .profiles #topBar > .container-fluid {
        display: flex;
    }
    .influencerInfo {
        margin-top: 0.5rem;
    }
    .influencerInfo span {
        display: block;
        margin-top: 0.25rem;
        width: max-content;
    }
    .influencerSettings .tab-content>.active {
        background-color: #F2F2F7;
        position: fixed;
        top: 0;
        padding-top: 1.5rem;
        padding-bottom: 0;
        height: 100%;
        min-height: 100vh;
        overflow-y: overlay;
        width: 100%;
        z-index: 99;
    }
    .influencerSettings .tab-content>.active::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .influencerSettings .tab-content>.active .bg-white.rounded-xl {
        border-radius: 0 !important;
        min-height: calc(100vh - 82px);
        border: 0 !important;
    }
    .influencerHistory table th:nth-of-type(4), 
    .influencerHistory table th:nth-of-type(5), 
    .influencerHistory table td:nth-of-type(4), 
    .influencerHistory table td:nth-of-type(5) {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .profiles .influencersTable th:nth-of-type(5), .profiles .influencersTable td:nth-of-type(5) {
        display: none;
    }
}

.categoryButton .btn-group {
    display: block;
}

.categoryButton .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.categoryButton .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
    z-index: 0;
}