.reportingArea {
    height: calc(100vh - 140px);
    overflow-y: overlay;
}
.reportingArea .spinner-container {
    height: calc(100vh - 350px) !important;
}

@media only screen and (max-width: 991px) {
    .reportingArea {
        height: calc(100vh - 120px);
    }  
}