.usersTable {
    height: calc(100vh - 223px);
    overflow-y: overlay;
    min-height: calc(100vh - 253px);
}

.fullscreenWithTopBar,
.fullscreenWithTopBar .bg-gray {
    background-color: var(--secondaryColor) !important;
}

.usersTable table thead {
    position: sticky;
    top: 8px;
    background: var(--secondaryColor);
    z-index: 9;
    height: 30px;
}

.usersTable table thead th {
    font-size: .9rem;
    font-weight: 500;
    border: 0;
}

.usersTable table.table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
}

.usersTable table.table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.usersTable table.table.border-transparent tbody td {
    padding: 1rem !important;
    background-color: white;
    border: 0;
}

.usersTable table.table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.form-floating>.form-select {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.UserSettings .associatedProfiles {
    overflow-y: visible;
    max-height: fit-content;
    min-height: 600px;
}

@media only screen and (max-width: 1280px) {
    .UserSettings table th:nth-of-type(2), 
    .UserSettings table td:nth-of-type(2) {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .usersTable {
        height: calc(100vh - 200px);
    }
    .usersTable::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .usersTable th:nth-of-type(3), .usersTable td:nth-of-type(3), 
    .usersTable th:nth-of-type(4), .usersTable td:nth-of-type(4),
    .usersTable th:nth-of-type(6), .usersTable td:nth-of-type(6) {
        display: none;
    } 
}

@media only screen and (max-width: 991px) {
    .usersTable {
        height: calc(100vh - 143px);
    }    
    .usersTable .table.table-striped.table-bordered.table-hover {
        padding-right: 1.5rem;
    }
    .usersTable th:nth-of-type(7), .usersTable td:nth-of-type(7) {
        display: none;
    } 
    .usersTable th:nth-of-type(2), .usersTable td:nth-of-type(2) {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } 
    .UserSettings .container {
        max-width: 100%;
    }
    .UserSettings table th:nth-of-type(3), 
    .UserSettings table td:nth-of-type(3) {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .usersTable {
        height: calc(100vh - 143px);
    }
    .pendingApplication #topBar {
        display: block;
    }
    .pendingApplication #topBar > .container-fluid {
        display: flex;
    }
    .usersTable th:nth-of-type(2), .usersTable td:nth-of-type(2) {
        max-width: 190px;
    } 
    .UserSettings .tab-content>.active {
        background-color: var(--secondaryColor);
        position: fixed;
        top: 0;
        padding-top: 1.5rem;
        padding-bottom: 0;
        height: 100%;
        min-height: 100vh;
        overflow-y: overlay;
        width: 100%;
        z-index: 99;
    }
    .UserSettings table th:nth-of-type(3), 
    .UserSettings table td:nth-of-type(3) {
        display: none;
    }
    .UserSettings .tab-content>.active .bg-white.rounded-xl {
        border-radius: 0 !important;
        min-height: calc(100vh - 82px);
        border: 0 !important;
    }
}

@media only screen and (max-width: 576px) {
    .usersTable th:nth-of-type(2), .usersTable td:nth-of-type(2) {
        display: none;
    } 
    .usersTable th:nth-of-type(1), .usersTable td:nth-of-type(1) {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } 
    .UserSettings table th:nth-of-type(4), 
    .UserSettings table th:nth-of-type(5), 
    .UserSettings table td:nth-of-type(4), 
    .UserSettings table td:nth-of-type(5) {
        display: none;
    }
}
