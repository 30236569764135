.listView {
    background-color: #fff;
    height: calc(100vh - 64px);
    overflow-y: overlay;
}

.listView .cardImage {
    height: 110px !important;
    width: 100px !important;
}

.listView .card-body {
    width: calc(100% - 100px);
    overflow: hidden;
}

.listView .cardTitle {
    display: inline-flex;
}

/* .listView span.badge.bg-event {
    margin-right: 0.5rem !important;
} */

.listView .backArrow {
    position: absolute;
    left: 0;
}

.listView .card {
    border: 1px solid #F1F1F1 !important;
}

@media only screen and (max-width: 767px) {

    .listView .card {
        padding-bottom: 1rem;
        border: 1px solid #eee !important;
        padding-right: 1.75rem;
        border-width: 0 0 1px 0 !important;
    }

    .listView .col-12.col-md-6.col-lg-4 {
        padding-right: 0;
    }

    .listView .sectionTitle.text-truncate {
        text-align: center;
    }
}