.notificationTable {
    height: calc(100vh - 168px);
    overflow-y: overlay;
}

.sentNotifications .table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.sentNotifications .table.border-transparent {
    border-color: transparent !important;
}

.sentNotifications .table.border-transparent thead th {
    padding: 0 1rem 0 0 !important;
    font-weight: 500;
    font-size: .9rem;
}

.sentNotifications .table.border-transparent thead th:first-child {
    padding-left: 1rem !important;
}

.sentNotifications .table.border-transparent tbody td {
    padding: 1rem 1rem 1rem 0 !important;
    background-color: white;
}

.sentNotifications .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    padding-left: 1rem !important;
}

.sentNotifications .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}