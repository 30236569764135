.referralCodeList {
    height: calc(100vh - 145px);
    overflow-y: overlay;
}

.referralCodeList .spinner-container {
    height: calc(100vh - 350px) !important;
}

.referCodeTable table thead {
    position: sticky;
    top: 8px;
    background: var(--secondaryColor);
    z-index: 9;
    height: 30px;
}

.referCodeTable table thead th {
    font-size: .9rem;
    font-weight: 500;
    border: 0;
}

.referCodeTable table.table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
}

.referCodeTable table.table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.referCodeTable table.table.border-transparent tbody td {
    padding: 1rem !important;
    background-color: white;
    border: 0;
}

.referCodeTable table.table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.referCodeTable {
    height: calc(100vh - 272px);
    overflow-y: overlay;
}