#topBar {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    max-width: 100%;
    background-color: var(--lightColor);
    height: 50px;
    border-style: none;
    color: var(--textColor);
    display: flex;
    padding: 0 .75rem 0 .5rem;
    min-height: 4rem;
    border-bottom: 1px solid #eee;
}

#topBar-logo {
    height: 50px;
    width: auto;
    padding: 0.5rem 0.5rem;
    color: white;
}

#topbar-right #menu-button {
    font-size: .85rem;
    text-transform: uppercase;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 4px;
    padding-left: 10px;
    border-width: 1px !important;
    border-color: #ddd !important;
    border-style: solid !important;
    border-radius: 50px;
    margin-right: 0;
}

#topbar-right .dropdown-toggle::after {
    display: none;
}

#topbar-right #menu-button:hover {
    background-color: #eee;
}

#topbar-right .dropdown-toggle::after {
    display: none !important;
}

.icon-avatar {
    width: 34px;
    border-radius: 50%;
    background-color: #ddd;
    object-fit: cover;
}

#topbar-right .dropdown-item {
    font-size: .875rem;
    line-height: 1.5;
}

#topbar-right .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.icon-list {
    margin: 0 2.5px;
    width: 14px;
}

.notification > a {
    border: 1px solid #ddd;
    border-radius: 50% !important;
    min-width: 42.6px;
    max-width: 42.6px;
    padding-top: 0 !important;
    padding-left: 5px !important;
    text-align: center;
    line-height: 38px;
}

.notification .dropdown-menu.dropdown-menu-end {
    padding: 0 !important;
}

.unreadBadge::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: limegreen;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.navbar-brand {
    cursor: pointer;
}

#topbar-right a.text-primary {
    color: var(--primaryColor) !important;
    font-weight: 400;
}

#topbar-right a.text-primary svg {
    fill: var(--primaryColor) !important;
}

#topbar-right a.text-primary:hover,
#topbar-right a.text-primary:active,
#topbar-right a.text-primary:focus {
    color: var(--primaryColor) !important;
}

#topbar-right a.text-primary.active {
    color: var(--primaryColor) !important;
    font-weight: 600;
}

#topbar-right a.text-primary.active svg {
    fill: var(--primaryColor) !important;
}

.leftSideMenuName {
    display: none;
}

#topbar-right .dropdown-item.toggleActive {
    font-weight: bold;
    color: #007AA6 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#topbar-right .dropdown-item.toggleActive > svg {
    display: flex;
}

#topbar-right .dropdown-item > svg {
    display: none;
}

@media only screen and (max-width: 1480px) {
    .leftSideMenuName {
        display: block;
    }
}

@media only screen and (max-width: 1323px) {
    .leftSideMenuName {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    #topBar-logo {
        padding: 0.5rem 1rem;
    }
    nav#topBar .container-fluid {
        padding-bottom: 0;
    }
    span.navbar-brand {
        margin-right: 0;
    }
    nav#basic-navbar-nav {
        padding: 0.5rem 0.8rem 0.5rem 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-expand .navbar-nav .dropdown-menu {
        top: auto;
        border-radius: 0;
        width: 100%;
        position: fixed !important;
        margin-top: 0.8rem;
        border: 0;
        box-shadow: 0 0 10px 0 #00000054;
        padding: 0;
    }
    #topbar-right .dropdown-item {
        font-size: 1rem;
        line-height: 1;
        padding: 1rem !important;
    }
    #topbar-right .notification > a#menu-button {
        border: 0 !important;
        min-width: 40px;
    }
    .icon-list {
        display: none;
    }
    #topbar-right #menu-button {
        font-size: .85rem;
        text-transform: uppercase;
        padding: 4px;
        border-width: 0px !important;
        border-radius: 50px;
        margin-right: 0;
    }
    .icon-avatar {
        width: 30px;
        height: 30px;
    }
    .navbar-expand .navbar-nav .dropdown-menu {
        width: auto;
        right: 0.5rem;
        border-radius: 0.5rem;
        max-width: 90%;
    }
    .dropdown-divider {
        margin: 0 !important;
    }
}