ul.nav.nav-tabs li.nav-item .nav-link {
    display: flex;
    align-items: center;
}

.tab-content {
    border-top: 0;
}

.tagsTitle {
    font-size: .75rem;
    color: #333;
}

.card {
    border: 1px solid #EFE4DA !important;
    position: relative;
}

.recommendedBadge {
    display: none;
}

.fade.termsModal.modal.show {
    background: #00000050;
}

.card.recommendedCard {
    border: 2px solid #007AA6 !important;
}

.card.recommendedCard .recommendedBadge {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
}

.card-header .card-text {
    font-size: .8rem;
}

.card .card-header {
    border-bottom: 1px solid #F1F1F1;
    background-color: transparent;
}

.card .card-footer {
    border-top: 1px solid #F1F1F1;
    background-color: transparent;
}

span.badge.bg-danger {
    background-color: #F94242 !important;
}

.tags {
    font-weight: 400;
    background-color: #e9e9e9;
    color: #333;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: .75rem;
}

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 400px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
    height: 100%;
    overflow-y: overlay;
    border: 0;
    border-radius: 0;
}

.selectedBids {
    max-height: 175px;
    overflow-y: overlay;
}

.selectedBids::-webkit-scrollbar {
    width: 4px;
}

.modalBottom .modal-dialog.modal-dialog-centered {
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 2rem;
}

.modal-header.text-white .btn-close {
    background: transparent url("../../images/modal-close-white.svg") center/1em auto no-repeat;
    opacity: .7;
}

.fade.toast.show {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1056;
}

.modal.right .modal-body {
    padding: 15px;
}

.modal.right.fade .modal-dialog {
    right: -400px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
    box-shadow: 0 0 15px 0 #333;
}

.fade.modal-backdrop.show {
    width: 400px;
    right: 0;
    left: auto;
    display: none;
}

.fade.modal-backdrop.show {
    opacity: 0.2 !important;
}

.fade.right.modal.show {
    width: 400px;
    left: auto;
    right: 0;
}

.campaignDetail .overlayBox {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
}

.campaignDetail .carousel-control-next,
.campaignDetail .carousel-control-prev,
.campaignDetail .carousel-indicators {
    display: none !important;
}

.campaignDetail .carousel-item img.w-100 {
    height: 40vh;
    object-fit: contain;
    object-position: center;
}

.campaignDetail .badge.bg-light {
    position: absolute;
    z-index: 9;
    bottom: 1rem;
    font-weight: 500;
}

.campaignDetail .badge.bg-light.left {
    left: 1rem;
}

.campaignDetail .badge.bg-light.right {
    right: 1rem;
}

.campaignDetail .logoBox {
    font-weight: 500;
    font-size: .9rem;
    color: #8d8d8d;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.campaignDetail ul.nav.nav-tabs.nav-fill {
    border-bottom-color: #F4F4F4;
}

.campaignDetail .nav-tabs .nav-link {
    color: #8d8d8d;
}

.campaignDetail .nav-tabs .nav-link:focus, 
.campaignDetail .nav-tabs .nav-link.active {
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: #2EC7DE;
    font-weight: 500;
    color: #000;
}

.campaignDetail .nav-tabs .nav-link:hover {
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: #2EC7DE;
}

.campaignPage .mobileView .nav.nav-tabs.border-0.flex-nowrap.overflow-auto.nav.nav-tabs::-webkit-scrollbar {
    display: none;
}

.pageTitleContainer .nav.nav-tabs .nav-link.active {
    border-bottom: 3px solid var(--secondaryColor) !important;
    color: var(--secondaryColor) !important;
}

.pageTitleContainer .nav.nav-tabs .nav-link {
    color: var(--secondaryColor) !important;
}

.pageTitleContainer .nav.nav-tabs .nav-link:hover {
    border-bottom: 3px solid var(--secondaryColor) !important;
}

.pageTitleContainer .nav.nav-tabs .nav-link span.badge.bg-primary {
    background: var(--secondaryColor) !important;
    color: var(--primaryColor) !important;
}

.nav.nav-tabs .nav-link.active {
    border-bottom: 3px solid var(--primaryColor) !important;
    border-width: 0 0 3px 0;
    font-weight: 500;
    color: var(--primaryColor);
}

.nav.nav-tabs .nav-link {
    border-bottom: 3px solid transparent !important;
    border-width: 0 0 3px 0;
    color: var(--primaryColor);
    padding: 0.5rem 0;
    margin-right: 1.5rem;
    font-weight: 300;
}

.nav.nav-tabs .nav-link:hover, 
.nav.nav-tabs .nav-link:focus {
    border-bottom: 3px solid var(--primaryColor) !important;
    border-width: 0 0 3px 0;
    color: var(--primaryColor);
}

span.badge.bg-info {
    background: #DAEDFF !important;
    color: #145EA9;
    display: block;
    font-weight: 500;
}

.mutedBadge {
    width: fit-content;
}

.pageTitleContainer select.form-select {
    border-radius: 20px;
    width: auto;
}

.pageTitleContainer div.form-control {
    border-radius: 20px;
}

.pageTitleContainer .border-bottom {
    border-bottom: 1px solid #F2F2F2 !important;
}

.campaignDetail ul.nav.nav-tabs li.nav-item .nav-link {
    justify-content: center;
}

.campaignDetail {
    padding-bottom: 0 !important;
}

.campaignDetail::-webkit-scrollbar-thumb {
    border: 4px solid var(--primaryColor);
}

.campaignDetail::-webkit-scrollbar-track {
    background-color: var(--primaryColor);
}

.imagesBoxView {
    height: 50vh;
}

.tabViewChange .badge:has(> img) {
    padding-top: 0;
    padding-bottom: 0;
}

.tabViewChange .badge img {
    margin-right: 4px;
    border-right: 1px solid #ccc;
    padding-right: 4px;
    height: 20px;
    width: 20px;
}

.tabViewChange .badge > img:nth-last-of-type(1) {
    border-right: 0;
    margin-right: 0;
}

.tabViewChange .badge strong {
    vertical-align: middle;
}

.tabViewChange ul.nav.nav-tabs.nav-fill {
    display: none;
}

.tabViewChange .tab-content .fade.tab-pane {
    display: block;
}

.campaignWall tbody td {
    padding: 1rem !important;
    border: 0 !important;
}

.campaignWall table.campaignTable.allTab tbody td > div.text-truncate.text-nowrap,
.campaignWall table.campaignTable.liveTab tbody td > div.text-truncate.text-nowrap,
.campaignWall table.campaignTable.expiredTab tbody td > div.text-truncate.text-nowrap,
.campaignWall table.campaignTable.archiveTab tbody td > div.text-truncate.text-nowrap {
    max-width: 280px;
}

.campaignWall thead th {
    border: 0 !important;
}

span.imageOverlay {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

span.imageOverlay > img {
    z-index: 1;
}

span.imageOverlay > span.textOverlay {
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: #00000095;
    text-align: center;
    color: white;
    line-height: 40px;
}

.campaignTable {
    border-collapse: separate;
    border-spacing: 0 8px;
    position: relative;
}

.campaignTable > thead {
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: var(--secondaryColor) !important;
}

.campaignAction.show.dropdown .dropdown-menu.show {
    right: 0 !important;
    left: auto !important;
    z-index: 8;
}

.campaignTable .w-95 {
    max-width: 95% !important;
}

.campaignWall .bg-gray {
    background-color: var(--lightColor) !important;
}

.campaignWall button.nav-link {
    padding-bottom: 0 !important;
}

.campaignWall span.notify {
    position: absolute;
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 2px;
    right: -5px;
    border: 2px solid white;
}

.campaignWall .mobileView .nav.nav-tabs.border-0 {
    overflow-x: overlay;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding: 0 1rem;
    height: 42px;
}

.campaignWall .mobileView .nav.nav-tabs .nav-link {
    padding: 0.5rem 0.85rem 0.5rem;
    margin-right: 0;
    font-size: .875rem;
    margin-top: 0.15rem;
}

.campaignWall .mobileView span.imageOverlay > span.textOverlay {
    height: 32px;
    width: 32px;
    line-height: 32px;
}

.campaignWall .mobileView button.nav-link {
    margin: 0.5rem 0 0 1.5rem;
}

.campaignWall img.mobileCampaignsList {
    object-fit: cover;
    height: 35vh;
}

.createCampaign .leftSideMenu {
    position: fixed;
    top: 64px;
}

.createCampaign .leftSideMenu a.active {
    color: #000;
    font-weight: 600;
    border-bottom: 3px solid #46A0FC;
}

.createCampaign .leftSideMenu a {
    display: table;
    margin-bottom: 0.5rem;
}

.createCampaignNav a {
    color: #4F4F4F;
    background-color: transparent !important;
}

.createCampaignNav a.active,
.nav.nav-tabs .createCampaignNav .nav-link.active {
    font-weight: 600;
    border-bottom: 0 !important;
}

.nav.nav-tabs .createCampaignNav .nav-link:hover, 
.nav.nav-tabs .createCampaignNav .nav-link:focus,
.nav.nav-tabs .createCampaignNav .nav-link {
    border-bottom: 0 !important;
}

.createCampaignNav a:hover {
    color: #2EC7DE;
}

.createCampaignNav a.active > span > svg > path {
    stroke: none;
    fill: #000;
}

.createCampaignNav .text-danger {
    color: #E50000 !important;
}

.createCampaignNav.bg-primary {
    background-color: #007AA6 !important;
}

.createCampaign .form-check.form-check-inline label.form-check-label {
    margin: 0;
    line-height: 24px;
}

.createCampaign .fullscreenWithTopBar {
    background: var(--secondaryColor) !important;
    scroll-behavior: smooth;
    height: calc(100vh - 64px);
    margin-bottom: 0;
}

.createCampaign hr {
    border-color: #bebebe !important;
}

.createCampaign .btn-group {
    display: block;
}

.createCampaign .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.createCampaign .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
}

.notificationTargets .btn-group .btn-check:checked+.btn {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--lightColor);
}

.notificationTargets .btn-group {
    display: block;
}

.notificationTargets .btn-group .btn.btn-primary {
    background-color: #FFF;
    border-color: #A7A7A7;
    color: #A7A7A7;
    font-weight: 400;
    border-radius: 0.375rem !important;
    font-size: 0.88rem;
}

.notificationTab {
    max-height: calc(100vh - 165px);
    overflow-y: overlay;
}

.sendNotification {
    max-height: calc(100vh - 190px);
    overflow-y: overlay;
}

.notificationHistory {
    max-height: calc(100vh - 190px);
    overflow-y: overlay;
}

.notificationHistory .table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.notificationHistory .table.border-transparent {
    border-color: transparent !important;
}

.notificationHistory .table.border-transparent thead th {
    padding: 0 1rem 0 0 !important;
    font-weight: 500;
    font-size: .9rem;
}

.notificationHistory .table.border-transparent thead th:first-child {
    padding-left: 1rem !important;
}

.notificationHistory .table.border-transparent tbody td {
    padding: 1rem 1rem 1rem 0 !important;
    background-color: white;
}

.notificationHistory .table.border-transparent tbody td:first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    padding-left: 1rem !important;
}

.notificationHistory .table.border-transparent tbody td:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
}

.createCampaignForm {
    max-height: calc(100vh - 270px);
    overflow-y: overlay;
    overflow-x: hidden;
    padding: 0 10px 0 3px;
    min-height: 180px;
}

.createCampaignForm::-webkit-scrollbar {
    width: 9px;
}

.createCampaignForm::-webkit-scrollbar-thumb {
    border: 0;
    background-color: #8b8264;
}

.createCampaignForm::-webkit-scrollbar-track {
    background-color: transparent;
}

#collaborations .nav.nav-tabs .nav-link {
    font-size: .87rem;
    padding-top: 0;
    font-weight: 400;
}

#collaborations .nav.nav-tabs .nav-link.active {
    background-color: transparent !important;
    font-weight: 500;
}

.collaborationsList {
    height: calc(100vh - 268px);
    overflow-y: overlay;
}

.collaborationsList.markedAsComplete,
.collaborationsList.completed {
    height: calc(100vh - 265px);
    overflow-y: overlay;
}

.searchBoxCollaborations {
    position: absolute;
    padding-right: calc(var(--bs-gutter-x) * .5);
    margin-top: 0.35rem;
    right: 0;
    top: 0;
}

.searchBoxCollaborations + ul.nav.nav-tabs {
    border: 0;
}

.notesCardList {
    padding: 0 !important;
    height: calc(100vh - 280px);
    overflow-y: overlay;
    position: relative;
    background: #fff;
}

.notesCardList::-webkit-scrollbar {
    width: 4px;
}

.notesSearch {
    border-bottom: 1px solid #e3e3e3;
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    background: #fff;
}

.notesCardList .notesCard {
    cursor: pointer;
    border-top: 1px solid #F1F1F1;
}

.notesCardList .notesCard.active,
.notesCardList .notesCard:hover {
    background-color: #F6F7F9 !important;
}

.notesCard {
    display: flex;
    align-items: center;
    padding: 1rem !important;
    overflow: hidden;
    width: 100%;
}

.notesEditor textarea { 
    resize: none; 
}

.messagesPage {
    height: calc(100vh - 210px);
}

.messagesPage .senderList {
    padding: 0 !important;
    max-height: 100%;
    overflow-y: overlay;
    position: relative;
    background: #fff;
}

.messagesPage .senderList::-webkit-scrollbar-track {
    background-color: #F2F2F7;
}

.messagesPage .senderList::-webkit-scrollbar-thumb {
    border-color: #F2F2F7;
    background-color: #8b8264;
}

.messagesPage button.nav-link.active {
    border-bottom: 3px solid #2EC7DE !important;
    border-width: 0 0 3px 0;
    font-weight: 500;
    color: #000000;
}

.messagesPage button.nav-link {
    border-bottom: 3px solid transparent !important;
    border-width: 0 0 3px 0;
    color: #8D8D8D;
    padding: 0.5rem 0;
    margin-right: 1.5rem;
}

.messagesPage .chatBody {
    height: calc(100vh - 346px);
    overflow: hidden;
}

.messagesPage .chatArea {
    padding: 1rem;
    padding-bottom: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    max-height: calc(100vh - 346px);
    overflow-y: overlay;
}

.messagesPage .chatFooter {
    padding: 0.5rem;
    border-bottom-right-radius: 0.25rem;
}

.tabViewChange .list-group.list-group-horizontal {
    max-width: 300px;
}

.campaignAction .dropdown-toggle::after {
    display: none;
}

.campaignAction .dropdown-item.active, 
.campaignAction .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.campaignPage .collaborationsList::-webkit-scrollbar-track {
    background-color: transparent;
}

.campaignPage .collaborationsList::-webkit-scrollbar {
    width: 9px;
}

.campaignPage .collaborationsList::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: #8b8264;
}

.campaignPage .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
}

.campaignPage button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}

.campaignPage img.blueTick {
    background: #fff;
    position: absolute;
    margin-left: 13px;
    width: 12px;
    margin-top: 13px;
    border-radius: 50%;
    border: 1px solid #fff;
    height: 12px;
}

/* .chatContainer.tab-content div#campaign-tab-tabpane-bids {
    overflow-y: overlay;
    height: calc(100vh - 288px);
    overflow-x: hidden;
} */

.noButton {
    height: calc(100vh - 227px) !important;
}

.imageWrapper {
    position: relative;
    display: inline-block;
}

.overlayBottomLeft {
    width: auto;
    height: auto;
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;
    color: #fff;
    cursor: pointer;
    padding: 5px 7px !important;
    line-height: 14px !important;
    font-size: .875rem !important;
}

.overlayBottomRight {
    position: absolute;
    right: 1.5rem;
    bottom: 0.5rem;
    background: #00000066;
    border-radius: 6px;
    padding: 0 5px;
    font-size: .875rem;
    color: #fff;
}

.overlayTopRight {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    cursor: pointer;
}

.multiUpload input.form-control {
    height: 150px;
    width: 200px;
    opacity: 0;
}

.multiUpload .multiUploadBox {
    height: 150px;
    width: 200px;
    border-style: dashed;
    border-width: medium;
    background: #fff;
    position: absolute;
    top: 0;
    text-align: center;
    line-height: 145px;
    color: #999;
    font-weight: 300;
    border-radius: 6px;
    border-color: #ccc;
}

.multiUpload {
    position: relative;
}

.form-check .form-check-input {
    margin-top: .1rem;
}

.video-play-button {
    color: #fff;
    display: block;
    font-size: 18px;
    left: 0;
    margin: 6em auto;
    padding: 8px 16px;
    width: 75px;
    position: absolute;
    right: 0;
    top: 30%;
}

.createCampaignFooter {
    padding-bottom: 0 !important;
    position: fixed;
    z-index: 9;
    bottom: 0;
    background: #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.brandTerms textarea {
    resize: none;
    outline: 0 !important;
}

.brandTerms textarea::selection {
    background-color: transparent;
}

.brandTerms textarea::-webkit-scrollbar {
    width: 4px;
}

.ratingHover {
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
}

.ratingHover:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.chatContainer.tab-content > .tab-pane > .row > .col-12 {
    min-height: calc(100vh - 230px);
}

.campaignWallTabs.container-fluid {
    height: calc(100% - 145px);
}

.campaignWallTabs.container-fluid .tab-content>.tab-pane {
    height: calc(100vh - 218px);
    overflow-y: overlay;
    padding: 0;
    overflow-x: hidden;
}

.campaignPhotoWall {
    height: calc(100vh - 155px);
    overflow-y: overlay;
    padding: 0;
    overflow-x: hidden;
}

.campaignPhotoWall .my-masonry-grid_column {
    padding-left: 1.5rem; /* gutter size */
    background-clip: padding-box;
}

.campaignPhotoWall .my-masonry-grid {
    margin-right: -0.5rem;
}

.campaignWallTabs::-webkit-scrollbar-thumb {
    border: 4px solid var(--secondaryColor);
}

.tab-pane::-webkit-scrollbar {
    width: 9px;
}

.tab-pane::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: #8b8264;
}

.tab-pane::-webkit-scrollbar-track {
    background-color: var(--secondaryColor);
}

.grow-wrap {
    display: grid;
    width: calc(100% - 78px);
    padding-right: 1rem;
}

button.bg-white.border {
    border: 1px solid #ced4da !important;
}

.popover {
    max-width: 380px !important;
    width: 100%;
}

.inboxSearch > .input-group > .rightClickFilter {
    position: absolute;
    z-index: 9;
    top: 44px;
    right: 1rem;
    background: #fff;
    border-radius: 0.375rem !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}

.rightClickFilter ul {
    list-style-type: none;
    padding: 0.5rem;
    margin: 0;
}

.rightClickFilter ul li {
    line-height: normal;
}

.rightClickFilter ul li span {
    cursor: pointer;
    margin-bottom: 0.5rem;
    margin-right: 0 !important;
}

.rightClickFilter ul li:last-child span {
    margin-bottom: 0;
}

.aboutYouBox img {
    margin-right: 0.5rem!important;
}

.aboutYouBox img.avatarIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    padding: 1px;
}

.campaignPage #imageViewer .carousel-control-next, 
.campaignPage #imageViewer .carousel-control-prev, 
.campaignPage #imageViewer .carousel-indicators {
    display: none !important;
}

.campaignPage #imageViewer .carousel-item img {
    height: 70vh;
}

.campaignImagePreview#imageViewer .carousel-item img {
    height: 80vh;
}

.campaignPage #imageViewer .carousel-item video {
    height: 70vh;
}

.campaignImagePreview#imageViewer .carousel-item video {
    height: 80vh;
}

.campaignPage #imageViewer .carousel-inner {
    padding-top: 16vh;
    padding-bottom: 14vh;
}

.campaignImagePreview#imageViewer .carousel-inner {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.campaignImagePreview#imageViewer .carousel-control-next, 
.campaignImagePreview#imageViewer .carousel-control-prev, 
.campaignImagePreview#imageViewer .carousel-indicators {
    display: none !important;
}

.campaignConversationModal > .conversationDetail {
    max-width: 100% !important;
    min-width: 100% !important;
}

.campaignConversationModal > .conversationDetail .chatHeader {
    display: none;
}

.biddersList > .pendingBids {
    overflow-y: overlay;
    height: calc(100vh - 345px);
    overflow-x: hidden;
    margin-top: 5px;
}

.biddersList > .rejectedBids,
.biddersList > .cancelledBids {
    overflow-y: overlay;
    height: calc(100vh - 218px);
    overflow-x: hidden;
    margin-top: 5px;
}

.advanceFilters {
    position: absolute;
    z-index: 9;
    background-color: var(--lightColor);
    border: 1px solid var(--primaryColor);
    padding: 1rem 1rem 0 1rem;
    border-radius: 1rem 0 0 1rem;
    right: 0;
    top: 0;
    width: 440px;
    display: block !important;
    max-height: calc(100vh - 338px);
    overflow-y: overlay;
    overflow-x: hidden;
    margin-right: -0.75rem;
    border-right: 0;
    scroll-behavior: smooth;
}

.closeFilters {
    position: absolute;
    right: 424px;
    z-index: 9;
    top: 15px;
    border-radius: 1rem 0 0 1rem !important;
}

.biddersList::-webkit-scrollbar-track {
    background-color: transparent;
}

.biddersList::-webkit-scrollbar {
    width: 9px;
}

.biddersList::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: #8b8264;
}

.viewCollaborators {
    max-height: 50vh;
    overflow-y: overlay;
    padding-right: 0;
}

.viewCollaborators::-webkit-scrollbar-track {
    background-color: transparent;
}

.viewCollaborators::-webkit-scrollbar {
    width: 14px;
}

.viewCollaborators::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    background-color: #8b8264;
}

.campaignPhotoWall .my-masonry-grid_column .ratingHover .likeDislikeThumbs {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: calc(50% - 32px - 1.5rem);
    right: calc(50% - 32px - 1.5rem);
    top: calc(80% - 56px);
    background: #143232da;
    width: calc(64px + 3rem);
    padding: 1rem;
    border-radius: 1rem;
    z-index: 9;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.campaignPhotoWall .my-masonry-grid_column .ratingHover:hover .likeDislikeThumbs {
    display: flex;
}

.unselectedInfluencer {
    opacity: 25%;
}

.influencerFilterSelection:hover {
    position: relative;
}

.influencerFilterSelection > div.position-relative > div {
    cursor: pointer;
}

.influencerFilterSelection > div.position-relative {
    width: 100%;
}

.influencerFilterSelection div.contributorName {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.influencerFilterSelection .likeDislikeThumbs {
    display: none;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    position: relative;
    right: 0;
    background: #143232da;
    padding: .5rem;
    border-radius: .5rem;
    z-index: 2;
    white-space: nowrap;
}

.influencerFilterSelection:hover .likeDislikeThumbs {
    display: flex;
}

.likeDislikeThumbs a .bi-hand-thumbs-up-fill:hover {
    fill: lawngreen;
}

.likeDislikeThumbs a .bi-hand-thumbs-down-fill:hover {
    fill: lightcoral;
}

button.viewMoreBtn {
    position: absolute;
    right: 1.25rem;
    width: auto;
    bottom: 0;
    border-radius: .25rem .25rem 0 0;
    z-index: 2;
    font-size: .7rem;
    padding: .25rem .35rem;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#filtersToggle {
    display: none;
    position: absolute;
    z-index: 5;
    right: 1.25rem;
    padding: 1rem 1rem .5rem;
    background: var(--secondaryColor);
    border: 1px solid var(--primaryColor);
    border-radius: .25rem;
    margin-top: .25rem;
}

.influencerList {
    flex-wrap: nowrap;
    overflow: hidden;
}

.influencerListModal {
    display: flex;
    position: absolute;
    z-index: 1;
    background: var(--lightColor);
    border: 1px solid var(--primaryColor);
    border-radius: .25rem;
    max-height: 50vh;
    overflow-y: auto;
    top: 88px;
    width: 100%;
    left: .7rem;
    padding-bottom: 1.5rem;
    padding-top: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.cardToggles {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: space-evenly;
    width: 15%;
}

.favouriteToggle img {
    width: 16px;
}

.socialDropdown {
    width: 40px;
    display: flex;
}

.socialDropdown button {
    width: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialDropdown button.show {
    background-color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
    color: var(--lightColor) !important;
}

.socialDropdown .dropdown-menu {
    padding: 0;
}

.socialDropdown .dropdown-menu .socialDropdownItem {
    padding: 5px 6px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 14px;
    line-height: 13px;
    cursor: pointer;
}

.socialDropdown .dropdown-menu .socialDropdownItem:hover,
.socialDropdown .dropdown-menu .socialDropdownItem.Selected:hover {
    background-color: #f2f1ec;
}

.socialDropdown .dropdown-menu .socialDropdownItem.Selected {
    background-color: #f2f1ec;
    font-weight: bold;
}

.socialDropdown .dropdown-menu .socialDropdownItem img {
    height: 20px;
    width: 20px;
}

@media only screen and (max-width: 1400px) {
    .campaignPage .container,
    .createCampaign .fullscreenWithTopBar .container,
    .campaignWall .container {
        max-width: 100%;
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 1300px) {
    .campaignTable.allTab th:nth-of-type(6), .campaignTable.allTab td:nth-of-type(6),
    .campaignTable.expiredTab th:nth-of-type(6), .campaignTable.expiredTab td:nth-of-type(6) {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .campaignPage .container,
    .createCampaign .fullscreenWithTopBar .container,
    .campaignWall .container {
        max-width: 100%;
        padding: 0 1rem;
    }
    .messagesPage .chatBody {
        height: calc(100vh - 411px);
    }
    .messagesPage .chatArea {
        max-height: calc(100vh - 411px);
    }
    /* .notificationTab {
        max-height: fit-content;
        overflow-y: visible;
    }     */
    .sendNotification {
        max-height: fit-content;
        overflow-y: visible;
    }    
    .notificationHistory {
        max-height: fit-content;
        overflow-y: visible;
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .messagesPage .senderList {
        width: 35%;
    }
    .messagesPage .conversationDetail {
        width: 65%;
        min-width: auto !important;
        max-width: initial !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .messagesPage {
        height: calc(100vh - 210px);
        margin-bottom: 0.25rem;
    }
    .messagesPage .chatArea {
        max-height: calc(100vh - 347px);
    }
    .messagesPage .chatBody {
        height: calc(100vh - 347px);
    }
    .tabViewChange ul.nav.nav-tabs.nav-fill {
        display: flex;
    }
    .tabViewChange .tab-content .fade.tab-pane {
        display: none;
    }
    .tabViewChange .tab-content>.active {
        display: block !important;
    }
    .campaignWallTabs.container-fluid {
        height: calc(100vh - 185px);
        overflow-y: overlay;
        padding-bottom: 0 !important;
    }
    .fullscreenWithTopBar.campaignDetail {
        height: calc(100vh - 60px);
        overflow-y: overlay;
        padding-bottom: 0 !important;
    }
    .campaignWall .pageTitleContainer {
        display: block;
    }
    .campaignsBrandFlow .mobileFooter,
    .createCampaign .mobileFooter,
    .inboxPage .mobileFooter {
        display: none;
    }
    .campaignWallTabs.container-fluid .table-responsive {
        overflow-y: hidden;
        min-height: calc(100vh - 230px);
    }
    .mobileBidBtn {
        position: fixed;
        bottom: 0;
        z-index: 9;
        border-radius: 0 !important;
        width: 100%;
    }
    .tabViewChange > .container,
    .tabViewChange > .container-fluid > .container {
        max-width: 100%;
        overflow-x: hidden;
    }
    .tabViewChange > .container-fluid {
        padding: 0px;
    }
    .campaignTable.allTab th:nth-of-type(2), .campaignTable.allTab td:nth-of-type(2), 
    .campaignTable.allTab th:nth-of-type(3), .campaignTable.allTab td:nth-of-type(3), 
    .campaignTable.allTab th:nth-of-type(4), .campaignTable.allTab td:nth-of-type(4) ,
    .campaignTable.expiredTab th:nth-of-type(2), .campaignTable.expiredTab td:nth-of-type(2), 
    .campaignTable.expiredTab th:nth-of-type(3), .campaignTable.expiredTab td:nth-of-type(3), 
    .campaignTable.expiredTab th:nth-of-type(4), .campaignTable.expiredTab td:nth-of-type(4) {
        display: none;
    }
    .campaignWall img.mobileCampaignsList {
        object-fit: cover;
        height: 40vh;
    }
    .campaignDetail + .mobileFooter, 
    .campaignPage + .mobileFooter {
        display: none;
    }
    .campaignPage h1.pageTitle {
        font-size: 1.2rem;
        padding-left: 0;
    }
    .campaignPage .pageTitleContainer, .campaignPage .pageName {
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .campaignPage .nav-item::-webkit-scrollbar {
        display: none;
    }
    .reportingTab {
        height: calc(100vh - 240px);
        overflow-y: auto;
    }
    .reportingTab::-webkit-scrollbar {
        height: 0 !important;
        width: 0 !important;
    }
    .biddersList {
        height: calc(100vh - 280px);
    }
    .collaborationsList::-webkit-scrollbar,
    .biddersList::-webkit-scrollbar {
        height: 0 !important;
        width: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .border.border-start-0 {
        border-color: #ccc !important;
    }
    .campaignPage .inboxSearch {
        z-index: 1;
    }
    .popover {
        max-width: 300px !important;
    }
    .campaignPage .nav.nav-tabs {
        gap: 0.5rem;
        scroll-behavior: smooth;
        flex-wrap: nowrap;
        text-wrap: nowrap;
        overflow-x: auto;
    }
    .campaignPage .nav.nav-tabs::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
    .collaborationsList {
        height: calc(100vh - 340px);
        overflow-y: overlay;
    }
    .collaborationsList.markedAsComplete, 
    .collaborationsList.completed {
        height: calc(100vh - 340px);
        overflow-y: overlay;
    }
    .chatContainer.tab-content div#campaign-tab-tabpane-bids {
        height: calc(100vh - 301px);
        padding-bottom: 10px;
    }
    .chatContainer.tab-content div#campaign-tab-tabpane-messages {
        height: calc(100vh - 240px) !important;
        overflow-y: overlay;
        overflow-x: hidden;
    }
    .tabViewChange ul.nav.nav-tabs.nav-fill {
        display: flex;
    }
    .tabViewChange .tab-content .fade.tab-pane {
        display: none;
    }
    .tabViewChange .tab-content>.active {
        display: block !important;
    }
    .tabViewChange > .container,
    .tabViewChange > .container-fluid > .container {
        max-width: 100%;
        overflow-x: hidden;
    }
    .headerCarousel {
        padding: 0 !important;
    }
    .messagesPage .chatBody {
        height: calc(100% - 152px);
        padding-right: 0;
        padding-top: 0;
    }
    .messagesPage .chatArea {
        max-height: calc(100vh - 152px);
        max-width: calc(100% - 1rem);
    }
    .campaignPage .shadow.position-fixed.bottom-0 {
        bottom: 60px !important;
        box-shadow: none !important;
        padding-right: 1rem;
        padding-bottom: 0;
    }  
    .campaignPage .chatContainer.tab-content>.tab-pane:first-child {
        padding-bottom: 50px;
    }
    .campaignPage .card-text img.me-1 {
        width: 20px;
    }
    .modal.right .modal-dialog {
        width: 100vw;
    }
    .fade.right.modal.show {
        width: 100vw;
    }
    .chatContainer.tab-content div#campaign-tab-tabpane-bids::-webkit-scrollbar {
        width: 4px;
    }  
    .campaignWall .pt-4.px-0.bg-white.mobileView.container {
        position: fixed;
        top: 0;
        z-index: 9;    
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }    
    .campaignWallTabs.container-fluid {
        margin-top: 0;
        height: calc(100vh - 175px);
        overflow-y: overlay;
        overflow-x: hidden;
        padding-bottom: 0 !important;
    }
    .fullscreenWithTopBar.campaignWall {
        padding-bottom: 0 !important;
    }
    .campaignWallTabs::-webkit-scrollbar {
        width: 2px;
    }  
    div#nav-tabMobile {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 1px;
    }
    div#nav-tabMobile::-webkit-scrollbar {
        height: 0;
    }
    .campaignWall.container-fluid {
        padding-bottom: 0 !important;
    }
    .createCampaign .fullscreenWithTopBar {
        margin-bottom: 0;
        height: calc(100vh - 60px);
        min-height: 50vh;
    }
    .createCampaign .fullscreenWithTopBar::-webkit-scrollbar {
        width: 3px;
    }
    .createCampaign .bg-white.shadow.position-fixed.bottom-0.z-index-9.container-fluid {
        padding-bottom: 0 !important;
    }
    .createCampaign .leftSideMenu {
        position: fixed;
        top: 0;
        display: flex;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        min-height: auto;
        width: calc(100% - 3px);
        background: #F2F2F7 !important;
        z-index: 10;
        padding-top: 2rem !important;
        padding-bottom: 0 !important;
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
        padding-left: 1rem;
    }
    .createCampaign .leftSideMenu a {
        display: flex;
        margin-bottom: 0.5rem;
    }
    .createCampaign .tab-content {
        padding-bottom: 2rem;
    }
    .createCampaignFooter {
        padding-bottom: 0 !important;
        position: fixed;
        z-index: 9;
        bottom: 59px;
        background: #fff;
        box-shadow: 0 -5px 1rem rgba(0,0,0,.15)!important;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    }
    .createCampaignFooter .btn {
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        --bs-btn-border-radius: 0.25rem;
    }
    .fade.toast.show {
        bottom: auto;
        top: .5rem;
        right: .5rem;
        z-index: 1056;
        width: calc(100% - 1rem);
    }
    .mobileBidBtn {
        position: fixed;
        bottom: 60px;
        z-index: 9;
        border-radius: 0 !important;
        width: 100%;
    }
    .campaignPage .container,
    .createCampaign .fullscreenWithTopBar .container {
        max-width: 100%;
        padding: 0 1rem;
    }
    .createCampaignNav {
        display: flex;
        justify-content: space-around;
        padding: 0 0.5rem;
    }
    .nav.nav-tabs .createCampaignNav .nav-link {
        width: max-content !important;
        padding: 0.75rem 0 !important;
        white-space: nowrap;
        margin-right: 0;
    }
    .campaignWall.container-fluid .container {
        max-width: 100%;
    }
    .campaignWall img.mobileCampaignsList {
        object-fit: cover;
        height: 40vh;
    }
    .campaignsBrandFlow .mobileFooter,
    .createCampaign .mobileFooter,
    .inboxPage .mobileFooter {
        display: block;
    }
    .collaborators img {
        width: 32px;
        height: 32px;
        object-fit: cover;
    }
    .collaborators span.imageOverlay > span.textOverlay {
        height: 32px;
        width: 32px;
        line-height: 32px;
    }
    .bg-page.adminMenu > header {
        display: none;
    }
    .bg-page.adminMenu .container {
        max-width: 100%;
    }
    .biddersList {
        height: calc(100vh - 355px);
    }
    .messagesPage {
        height: calc(100vh - 240px);
    }
    .messagesPage .senderList {
        height: calc(100vh - 240px);
        border-radius: 0 !important;
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -1rem; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 1rem; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 1rem;
}

/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
    .my-masonry-grid {
      margin-left: -15px; /* gutter size offset */
    }
    .my-masonry-grid_column {
      padding-left: 15px; /* gutter size offset */
    }
    .my-masonry-grid_column > div {
      margin-bottom: 15px; /* space between items */
    }
}