.homePage header.site-header {
    display: block;
}

.fullscreenCarousel .cardImage {
    height: 220px;
}

.fullscreenCarousel {
    display: flex;
    gap: 1rem;
    padding-top: 1rem;
}

.fullscreenCarousel .card {
    width: 25%;
}

/* .homePage .pageTitleContainer {
    display: none;
} */

.wallCards .card > .card-body {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
}

.overlayExpired {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    background: #143232ad;
    border-radius: 6px;
    padding: 0 5px;
    font-size: .875rem;
    color: #fff;
}

.recommendedTag {
    background-color: var(--primaryColor) !important;
    color: #fff;
    border-radius: var(--bs-card-border-radius);
    font-size: .875rem;
}

.communityTag {
    background-color: var(--primaryColor) !important;
    color: #fff;
    border-radius: var(--bs-card-border-radius);
    font-size: .875rem;
}

.overlayType {
    position: absolute;
    left: 1.5rem;
    bottom: 1.5rem;
    background: #143232ad;
    border-radius: 6px;
    padding: 0 5px;
    font-size: .875rem;
    color: #fff;
    cursor: pointer;
}

.homePage .cardScrollView p.text-muted {
    font-size: 1rem !important;
}

.homePage .cardScrollView {
    min-height: calc(100vh - 195px);
}

.homePage .cardScrollView .reachedBottom {
    top: 80vh;
}

.homePage.wallPage .reachedBottom {
    top: 82vh;
}

/* .homePage .cardScrollView .cardTitle {
    line-height: .9rem;
    height: 32px;
    align-items: center;
    display: flex;
} */

div#categories .css-13cymwt-control {
    max-height: 30px;
    min-height: 30px;
}

div#categories .css-13cymwt-control span.css-1u9des2-indicatorSeparator {
    display: none;
}

div#categories div#react-select-2-placeholder {
    font-size: .95rem;
    color: #000;
}

div#categories .css-qbdosj-Input {
    padding: 0;
}

div#categories .css-1fdsijx-ValueContainer {
    height: 30px;
    padding: 0 8px;
}

div#categories .css-1xc3v61-indicatorContainer {
    padding: 0 8px;
}

div#categories svg.css-tj5bde-Svg {
    margin-bottom: 2px;
}

div#categories svg.css-tj5bde-Svg path {
    fill: #777;
}

@media (min-width: 990px) and (max-width: 991px) {
    .pageTitleContainer {
        padding-bottom: 0 !important;
    }
    .fullscreenWithTopBar > .cardScrollView {
        margin: 0;
        max-width: 100%;
    }
    .fullscreenWithTopBar > .cardScrollView > .wallCards > .multiCarousel .card:last-child {
        margin-right: 1rem;
    }
    .wallCards > .multiCarousel {
        overflow-x: overlay;
        scroll-behavior: smooth;
        padding-bottom: 0.15rem;
    }
    .wallCards > .multiCarousel::-webkit-scrollbar {
        display: none;
    }
    .wallCards > .multiCarousel .card {
        max-width: 30%;
        min-width: 30%;
    }
    .wallPage > .container-fluid {
        padding: 0;
    }
}

@media only screen and (max-width: 991px) {
    .fullscreenCarousel {
        white-space: nowrap;
        overflow-x: auto;
        display: block;
    }
    .fullscreenCarousel .card {
        width: 90%;
        display: inline-flex;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .wallPage #topBar {
        position: fixed;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        display: block;
    }
    .wallPage #topBar > .container-fluid {
        display: flex;
    }
    .wallPage .fullscreenWithTopBar {
        height: calc(100vh - 64px);
        width: 100%;
        position: relative;
        margin-top: 64px;
        overflow: overlay;
        background-color: #F6F3F0;
    }
    .wallPage .fullscreenWithTopBar::-webkit-scrollbar {
        display: none;
    }
    .wallCards .multiCarousel {
        white-space: normal;
        flex-direction: column;
        padding-right: 1rem !important;
    }
    .wallCards .multiCarousel > .card {
        display: flex;
        margin-right: 0;
        margin-bottom: 0;
        min-width: 100%;
    }
    .wallCards .card > .card-body {
        white-space: normal;
    }
    .wallCards .card .cardImage {
        height: 150px;
    }
    .homePage #topBar {
        display: block;
    }
    .homePage #topBar > .mobileHeader {
        display: block;
    }
    .homePage .fullscreenWithTopBar {
        height: calc(100vh - 64px);
        width: 100%;
        position: relative;
        overflow: overlay;
        background-color: #F6F3F0;
    }
    .homePage .fullscreenWithTopBar::-webkit-scrollbar {
        display: none;
    }
    .homePage .cardImage {
        height: 100%;
        min-height: 190px;
        max-height: 190px;
    }
}

@media only screen and (max-width: 1400px) {
    .homePage .container.homePageFilters {
        max-width: 1400px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .homePage .cardImage {
        height: 100%;
        min-height: 160px;
        max-height: 160px;
    }
    .row-cols-1.row-cols-sm-1 .p-1 {
        padding: 1rem !important;
        padding-top: 0 !important;
    }
    .overlayExpired {
        left: 1.5rem;
        bottom: auto;
        right: auto;
        top: 1.5rem;
    }
    .customHeaderMultiSelect button.dropdown-toggle {
        width: 100%;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem !important;
    }
}

/* customizable snowflake styling */
.snowflake {
	color: #fff;
	font-size: 1em;
	font-family: Arial, sans-serif;
	text-shadow: 0 0 5px #000;
}

.snowflake,
.snowflake .inner {
	animation-iteration-count: infinite;
	animation-play-state: running
}

@keyframes snowflakes-fall {
	0% {
		transform: translateY(0)
	}

	100% {
		transform: translateY(110vh)
	}
}

@keyframes snowflakes-shake {

	0%,
	100% {
		transform: translateX(0)
	}

	50% {
		transform: translateX(80px)
	}
}


/* Christmas Feature */
.snowflake {
	position: fixed;
	top: -10%;
	z-index: 9999;
	-webkit-user-select: none;
	user-select: none;
	cursor: default;
	animation-name: snowflakes-shake;
	animation-duration: 3s;
	animation-timing-function: ease-in-out
}

.snowflake .inner {
	animation-duration: 10s;
	animation-name: snowflakes-fall;
	animation-timing-function: linear
}

.snowflake:nth-of-type(0) {
	left: 1%;
	animation-delay: 0s
}

.snowflake:nth-of-type(0) .inner {
	animation-delay: 0s
}

.snowflake:first-of-type {
	left: 10%;
	animation-delay: 1s
}

.snowflake:first-of-type .inner,
.snowflake:nth-of-type(8) .inner {
	animation-delay: 1s
}

.snowflake:nth-of-type(2) {
	left: 20%;
	animation-delay: .5s
}

.snowflake:nth-of-type(2) .inner,
.snowflake:nth-of-type(6) .inner {
	animation-delay: 6s
}

.snowflake:nth-of-type(3) {
	left: 30%;
	animation-delay: 2s
}

.snowflake:nth-of-type(11) .inner,
.snowflake:nth-of-type(3) .inner {
	animation-delay: 4s
}

.snowflake:nth-of-type(4) {
	left: 40%;
	animation-delay: 2s
}

.snowflake:nth-of-type(10) .inner,
.snowflake:nth-of-type(4) .inner {
	animation-delay: 2s
}

.snowflake:nth-of-type(5) {
	left: 50%;
	animation-delay: 3s
}

.snowflake:nth-of-type(5) .inner {
	animation-delay: 8s
}

.snowflake:nth-of-type(6) {
	left: 60%;
	animation-delay: 2s
}

.snowflake:nth-of-type(7) {
	left: 70%;
	animation-delay: 1s
}

.snowflake:nth-of-type(7) .inner {
	animation-delay: 2.5s
}

.snowflake:nth-of-type(8) {
	left: 80%;
	animation-delay: 0s
}

.snowflake:nth-of-type(9) {
	left: 90%;
	animation-delay: 1.5s
}

.snowflake:nth-of-type(9) .inner {
	animation-delay: 3s
}

.snowflake:nth-of-type(10) {
	left: 25%;
	animation-delay: 0s
}

.snowflake:nth-of-type(11) {
	left: 65%;
	animation-delay: 2.5s
}