@keyframes spin-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;    
    background-color: var(--secondaryColor);
}

.spinner > svg {
    height: 100px;
    width: 100px;
}

.spinner > svg > .path {
    animation: spin-animation 1.5s infinite ease-in-out;
    transform-origin: center;
}

.spinner > svg > path {
    fill: var(--primaryColor);
}