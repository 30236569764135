.CampaignDiagnostics {
    height: 100vh;
    display: flex;
    overflow-y: scroll;
}

.DiagnosticsSelection {
    cursor: pointer;
}

.DiagnosticsSelection.Selected {
    font-weight: bold;
}

.DiagnosticsPage {
    table, th, td {
        border: 1px solid;
      }
} 