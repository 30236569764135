@media only screen and (max-width: 991px) {
    .emailVerify {
        height: 100vh;
        overflow-x: hidden;
        padding: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .emailVerify img.position-fixed {
        height: 60px;
    }
}

@media only screen and (max-width:767px) {
    html, body {
        padding: 0 !important;
    }
    .signupBody::-webkit-scrollbar {
        width: 4px;
    }
    .emailVerify .col-md-4 {
        height: 25vh !important;
        justify-content: center !important;
    }
    .emailVerify .col-md-4 img.position-fixed {
        height: 50px;
    }
    .emailVerify .col-md-8 {
        height: 75vh !important;
    }
}